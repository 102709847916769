<template>
	<div class="">
		<div class="">
			<div class="">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
						<button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" @click="mostrarFavoritos">Crédito</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" @click="mostrarFavoritos">Opciones de crédito</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false" @click="mostrarFavoritos">Productos</button>
					</li>
					<li class="nav-item" role="presentation" v-if="muestraFavoritos">
						<button class="nav-fav" id="fav-tab" data-bs-toggle="tab" data-bs-target="#fav" type="button" role="tab" aria-controls="fav" aria-selected="false" style="margin-left: 150px;">
							<div class="vx-col" v-if="caso.favoritos.length > 0">
								<span class="cursor-pointer" @click="openFavs()">Ver Favoritos <span class="font-medium">({{ caso.favoritos.length }})</span></span>
							</div>
							<div class="vx-col" v-if="caso.favoritos.length == 0">
								<span class="cursor-pointer">
									Ver Favoritos 
									<span class="font-medium">({{ caso.favoritos.length }})</span>
								</span>
							</div>
						</button>
					</li>
					<li class="nav-item" role="presentation" v-if="muestraFavoritos"  style="padding-left: 5px;">
						<!--<div class="vx-col" v-if="this.caso.curp!=1"> -->
						<div class="vx-col">
							<button class="nav-link" type="button"  style="background: #079DEF;" @click="openPopUp(ligaCliente)">
								<span class=" cursor-pointer" style="color: white;" >Enviar liga para llenado de solicitud</span>
							</button> 
							<ul v-if="ligaCliente && this.socData" class="LigaCliente">
								<li v-if="this.IdLiga != '' && this.IdLiga != null">
									<div class="modal-content" style="margin: 0px 150px 30px 0px;">
										<div style="margin: 0px 0px 5px 0px;">
											<p class="titleLiga">La liga del cliente es la siguiente: </p>
												<table>
													<tr>
														<td ALIGN="right">
															<label style="margin-top: 15px;"> URL: </label>
														</td>
														<td>
															<input class="vs-input" v-model="url" name="url" ref="message"/>
														</td>
														<td></td>
													</tr>
												</table>
												<table>
													<tr>
														<td>
															<div class="justify-content-md-right">
																<button type="button" class="btn btn-secondary" data-dismiss="modal" style="background-color: #079DEF; font-weight: bolder; width: 50%; margin-left: 80px;" @click="copiarLiga()">Copiar</button> 
															</div>
														</td>
														<td>
															<div class="justify-content-md-left">
																<button type="button" class="btn btn-secondary" data-dismiss="modal" style="background-color: #006D4E; font-weight: bolder; width: 70%;" @click="EnviaCorreo()">Enviar por correo</button> 
															</div>
														</td>
													</tr>
												</table>
										</div>
									</div>
								</li>
								<li  v-else>
									<div class="modal-content" style="margin: 0px 0px 30px 0px;">
										<div  style="margin: 10px 10px 15px 0px;">
											<p class="titleLiga"> Para enviar la liga del cliente favor de ingresar la CURP </p>
												<table> 
													<tr>
														<td ALIGN="right">
															<label style="margin-top: 10px;"> CURP </label>
														</td>
														<td>
															<input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();" style="height:25px;"/>
														</td>
														<td>
															<div class="justify-content-md-left">
																<button type="button" class="btn btn-secondary" data-dismiss="modal" style="background-color: #079DEF; font-weight: bolder; width: 80%; height:30px; margin: 0px 0px -5px 0px;" @click="dameTokenV3()" :disabled="!validateForm">Guardar</button> 																
															</div>
														</td>
													</tr>
												</table>
										</div>
									</div>
								</li>
							</ul>
							<ul v-if="ligaCliente && this.socData == null" class="LigaCliente">
								<li>
									<div class="modal-content" style="margin: 0px 0px 30px 0px;">
										<div  style="margin: 10px 10px 15px 0px;">
											<p class="titleLiga"> Para enviar la liga del cliente favor de ingresar la CURP </p>
												<table> 
													<tr>
														<td ALIGN="right">
															<label style="margin-top: 10px;"> CURP </label>
														</td>
														<td>
															<input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();" style="height:25px;"/>
														</td>
														<td>
															<div class="justify-content-md-left">																
																<button type="button" class="btn btn-secondary" data-dismiss="modal" style="background-color: #079DEF; font-weight: bolder; width: 80%; height:30px; margin: 0px 0px -5px 0px;" @click="dameTokenV3()" disabled>Guardar</button> 
															</div>
														</td>
													</tr>
												</table>
										</div>
									</div>
								</li>
							</ul>
						</div>
						
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<vx-card :title="'Folio ' + id" class="tab-body">
							<div class="text-center" v-if="!state_data">
								<label class="text-danger">Recuerda actualizar la fecha de nacimiento e información de ingresos del solicitante</label>
							</div>
							<div id="datosCotizacion">
								<div class="vx-row vs-con-loading__container" v-if="items.length"></div>
							</div>
							<div class="">
								<div class="">

									<div class="row w-full">
										<div class="col-md-8">
											<span class="span-placeholder" for="lineaNegocio">Línea de negocio</span>
											<select
												v-model="caso.PrecalificacionTipo" 
												name="lineaNegocio" 
												id="lineaNegocio" 
												class="v-select vs--single  vs-input--input">
												<option :value="tipo.Id" v-for="tipo in productoTipoList" :key="tipo.Id">{{ tipo.Nombre }}</option>
											</select>
										</div>
									</div>

									<div class="row w-full">
										<div class="col-md-8">
											<span class="span-placeholder" for="destinos">Destino</span>
											<select name="cars" id="destinos" v-model="destino" class="vs-input" @change="mostrarProgramas({ isCambioBancos: false }), cambiarBancosAutos()">
												<option :value="tipo.Id" v-for="tipo in destinos" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-4" v-if="(destino == 1 || destino == 5 || destino == 2 || destino == 20 || destino == 19 || destino == 15) && caso.PrecalificacionTipo == 1">
											<span class="span-placeholder" for="Programas">Programas</span>
											<select name="cars" id="Programas" v-model="caso.programa" class="vs-input" @change="cambia()">
												<option :value="tipo.Id" v-for="tipo in programas" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
									</div>

									<Automotriz 
										v-if="caso.PrecalificacionTipo == 3 && (destino == 41 || destino == 42 || destino == 43 || destino == 45 || destino == 46 || destino == 47)"
										:otro="otro"
										:destinoAuto="destino"
										:porcentajeMaximo="PorcentajeMaximo"
										v-on:updateValores="update($event)" />
									<AdquisicionCofi 
										:programa="caso.programa" 
										:Destino="destino" 
										:otro="otro" 
										v-if="(destino == 1 || destino == 19) && (caso.programa == 4 || caso.programa == 9) && caso.PrecalificacionTipo == 1"
										v-on:updateValores="update($event)" 
										v-on:buscaBancos="cambiaBancos($event)"/>
									<Adquisicion 
										:otro="otro" 
										v-if="(destino == 1 || destino == 24 || destino == 21 || destino == 3 || destino == 27 || destino == 15 || destino == 8 || destino == 10 || destino == 32 || destino == 37 ) && (caso.programa == 1 || caso.programa == 6) && caso.PrecalificacionTipo == 1" 
										v-on:updateValores="update($event)" 
										v-on:buscaBancos="cambiaBancos($event)" 
										:porcentajeMaximo="PorcentajeMaximo" />
									<Mejora 
										:otro="otro" 
										v-if="(destino == 25 || destino == 22 || destino == 33 || destino == 34 || destino == 19) && caso.PrecalificacionTipo == 1" 
										v-on:updateValores="update($event)" 
										v-on:buscaBancos="cambiaBancos($event)" />
									<TerrenoProgramas 
										:programa="caso.programa" :otro="otro" 
										v-if="(destino == 5 || destino == 20 || destino == 2) && (caso.programa == 4 || caso.programa == 9) && caso.PrecalificacionTipo == 1" 
										v-on:updateValores="update($event)" />
									<Terreno 
										:destino="destino" 
										:otro="otro" 
										v-if="(destino == 5 || destino == 20 || destino == 2 || destino == 37) && (caso.programa == 1 || caso.programa == 6) && caso.PrecalificacionTipo == 1" 
										v-on:updateValores="update($event)" 
										v-on:buscaBancos="cambiaBancos($event)" 
										:porcentajeMaximo="PorcentajeMaximo" />
									
									<div
										v-if="caso.PrecalificacionTipo == 1" 
										class="row w-full">
										<div class="col-md-4">
											<span class="text-sm" for="gastosNotariales">Gastos notariales</span>
											<select name="gastosNotariales" id="gastosNotariales" v-model="caso.gastosNotarial" class="v-select vs--single  vs-input--input">
											<option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
											</select>
										</div>
									</div>	

								<!-- Ministraciones -->
									<div 
										class="row w-full" 
										v-if="(destino == 2 || destino == 8 || destino == 20 || destino == 21 || destino == 10 || destino == 32) && caso.PrecalificacionTipo == 1">
										<div class="col-md-4">
											<span 
												class="text-sm" 
												for="hasAportacion">Uso de Ministraciones
											</span>

											<div class="flex justify-between col-md-5 mt-2">
												<div class="flex items-center">
													<vs-radio 
														v-model="usoMinistraciones" 
														vs-name="usoMinistraciones" 
														vs-value="No"></vs-radio>

													<span class="span-placeholder">No</span>
												</div>
												<div class="flex items-center">
													<vs-radio 
														v-model="usoMinistraciones" 
														vs-name="usoMinistraciones" 
														vs-value="Si"></vs-radio>

													<span class="span-placeholder">Si</span>
												</div>
											</div>
										</div>
									</div>
									<div  v-if="usoMinistraciones== 'Si'">
										<div class="row w-full">
											<div class="col-md-4">
												<span class="span-placeholder" for="destinos">Plazo de construcción (meses)</span>
												<select name="cars" id="plazoContruccion" v-model="plazoContruccion" class="vs-input" @change="updatePeriodo()">
													<option :value="plazo.value" v-for="plazo in plazoMinistraciones" :key="">{{ plazo.label }}</option>
												</select>
											</div>									
											<div class="col-md-4">
												<span class="span-placeholder" for="destinos">Periodo de ministraciones</span>
												<select name="cars" id="periodo" v-model="periodo" class="vs-input" @change="updateCampos()">
													<option :value="periodo.value" v-for="periodo in periodoMinistraciones" :key="">{{ periodo.label }}</option>
												</select>
											</div>
										</div>
										<div class="row w-full">
											<div class="col-md-4" v-if="campos">
												<span class="span-placeholder" for="destinos">Porcentaje de ministraciones</span><br>
											</div>											
										</div>
										<div class="row w-full" v-if="campos">
											
											<!--<div class="flex items-center" id="porcentajesMinistracion"> -->
											<div class="col-md-1 flex items-center" id="porcentajesMinistracion" v-for="item in campos">

												<div id="inputFormRow" v-if="item <= 9">
														<span class="span-placeholder" for="destinos"> {{ item }}</span>
													<div class="flex items-center">												
														<input type="text" v-model="camposID.name[item]" class="form-control" v-on:blur="calculaPorcentaje()" style="padding-left:10%;" v-on:keypress="SoloNumero($event)"> %'
													</div>			
												</div>																																				
											</div>
											
										</div>
										<div class="row w-full" v-if="campos">
											<div class="col-md-1 flex items-center" id="porcentajesMinistracion2" v-for="item in campos"  v-if="item > 9">
												<div id="inputFormRow">
														<span class="span-placeholder" for="destinos"> {{ item }}</span>
													<div class="flex items-center">												
														<input type="text" v-model="camposID.name[item]" class="form-control" v-on:blur="calculaPorcentaje()" style="padding-left:10%;" v-on:keypress="SoloNumero($event)"> %'
													</div>			
												</div>																							
											</div>
										</div>
										<span id="alert" class="text-danger span-placeholder" style="display: none; color: red; ">La suma de los porcentajes de ministraciones tiene que ser 100%</span>
									</div>
									
									
									<div class="row w-full">
										<div class="col-md-8">
											<span class="span-placeholder">Bancos</span>
											<v-select v-model="caso.bancos" multiple :closeOnSelect="false" :options="bancos" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-8 align-blue">
											<vs-button class="btn-blue" @click.prevent="seleccionarTodos()">Seleccionar todo</vs-button>
											<vs-button class="btn-blue" @click.prevent="limpiarBancos()">Limpiar Selección</vs-button>
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-3">
											<span class="span-placeholder" for="ejecutivos">Ejecutivo</span>
											<select name="cars" id="ejecutivos" v-model="caso.ejecutivo" class="vs-input">
												<option :value="tipo.EMail" v-for="tipo in ejecutivos" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-3">
											<span class="span-placeholder" for="estados">Estado</span>
											<select name="cars" id="estados" v-model="caso.estado" class="vs-input" @change="getPlazas()">
												<option :value="tipo.Id" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-3">
											<span class="span-placeholder" for="plaza">Plaza*</span>
											<select name="cars" id="plaza" v-model="caso.plaza" class="vs-input">
												<option :value="tipo.Id" v-for="tipo in plazas" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-3">
											<span class="span-placeholder" for="estatus">Estatus del caso</span>
											<select name="cars" id="estatus" v-model="caso.estatus" class="vs-input">
												<option :value="tipo.Id" v-for="tipo in estatus" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div
											v-if="caso.PrecalificacionTipo == 1" 
											class="col-md-3">
											<span class="span-placeholder" for="estatus">Perfil de cliente</span>
											<select name="cars" id="tasas" v-model="caso.tasa" class="vs-input">
												<option :value="tipo.Id" v-for="tipo in tasas" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div
											v-if="caso.PrecalificacionTipo == 1" 
											class="col-md-3">
											<ul class="con-s" style="margin-top: 5%; margin-left: 5%">
												<li>
													<span class="span-placeholder" for="">¿El inmueble es nuevo?</span>
													<vs-switch v-model="caso.inmuebleNuevo" />
												</li>
											</ul>
										</div>
									</div>

									<!-- aportaciones voluntarias -->
									<div
										v-if="caso.PrecalificacionTipo == 1" 
										class="row w-full">
										<div class="col-md-4">
											<span 
												class="text-sm" 
												for="hasAportacion">Aportaciones voluntarias
											</span>

											<div class="flex justify-between col-md-5 mt-2">
												<div class="flex items-center">
													<vs-radio 
														v-model="hasAportacion" 
														vs-name="hasAportacion" 
														vs-value="No"></vs-radio>

													<span class="span-placeholder">No</span>
												</div>
												<div class="flex items-center">
													<vs-radio 
														v-model="hasAportacion" 
														vs-name="hasAportacion" 
														vs-value="Si"></vs-radio>

													<span class="span-placeholder">Si</span>
												</div>
											</div>
										</div>
									</div>

									<!-- campos ocultos -->
									<div
										v-if="hasAportacion == 'Si' && caso.PrecalificacionTipo == 1" 
										class="row w-full">

										<div class="col-md-3">
											<span class="span-placeholder" for="tipoPrepago">Tipo prepago</span>
											<select 
												v-model="tipoPrepago"
												name="tipoPrepago" 
												id="tipoPrepago" 
												class="vs-input">
												<option :value="''">-- Seleccione --</option>
												<option 
													:value='prepago.Id' 
													v-for="prepago in prepagoList">{{ prepago.Nombre }}
												</option>
											</select>
										</div>

										<div class="col-md-3">
											<span class="span-placeholder" for="aportacionVoluntaria">Aportación voluntaria</span>
											<div>
												<span class="symbol-currency col-12">
													<input 
														v-model="aportacionVoluntaria"
														type="text"
														v-on:keyup="checkApoVol()"
														@change="setFormatApoVol()"
														v-on:keypress="isNumber($event)"
														class="vs-input input-currency"
														id="aportacionVoluntaria"
														name="aportacionVoluntaria"/>
												</span>
											</div>
										</div>

									</div>

									<div
										v-if="hasAportacion == 'Si' && caso.PrecalificacionTipo == 1"
										class="row w-full">

										<div class="col-md-3">
											<span class="span-placeholder" for="tipoPlazo">Plazo</span>
											<select 
												v-model="tipoPlazo"
												@change="setPlazoAportacion()"
												name="tipoPlazo" 
												id="tipoPlazo" 
												class="vs-input">
												<option :value="''">-- Seleccione --</option>
												<option 
													:value='plazo.Id' 
													v-for="plazo in plazosList">{{ plazo.Nombre }}
												</option>
											</select>
										</div>

										<div class="col-md-3">
											<span class="span-placeholder" for="numeroAportacion">Número de aportaciones</span>
											<input 
												v-model="numeroAportacion"
												type="text"
												
												class="vs-input"
												id="numeroAportacion"
												name="numeroAportacion"/>
												
											<span 
												name="numAportaHelper"
												id="numAportaHelper"
												class="text-danger span-placeholder">
												{{ helperCantidadAportacion }}
											</span>
										</div>

									</div>
									<!-- aportaciones voluntarias -->

									<br />
									<button class="btn btn-second" id="guardarCotizar" @click="updateCaso()" :disabled="!validaPlaza">Guardar y cotizar</button>
								</div>
							</div>
							
							<div
								v-if="caso.PrecalificacionTipo == 1" 
								class="extrasVentaDigital">
								<p>Extras Venta Digital</p>
								<vs-textarea v-model="ExtrasVentaDigital" :value="ExtrasVentaDigital" rows="4" no-resize disabled></vs-textarea>
							</div>
						</vx-card>
					</div>
					<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab" style="position: inherit; z-index: 1;"> <!--JORGE-->
						<div class="tab-body-3">
							<div id="cotizacion">
								<div class="vx-row vs-con-loading__container" v-if="items.length"></div>
							</div>
							<div class="">
								<table style="background-color: #f1f4ff; border-radius: 5px">
									<tr style="background-color: #f1f4ff; border-radius: 5px">
										<td class="ocultar-celda col-md-2 td-modal2">
											<p class="text-grey mb-0" id="title" v-if="caso.programa == 9 || caso.programa == 4">Resumen de crédito</p>
											<span v-if="caso.programa == 9 || caso.programa == 4">Valor inmueble:{{ caso.valorInmueble }}</span>
											<br v-if="caso.programa == 9 || caso.programa == 4" />
											<span v-if="caso.programa == 9 || caso.programa == 4">Monto de crédito:{{ caso.montoSolicitado }}</span>
										</td>
										<td class="ocultar-celda col-md-3 td-modal2">Descripción</td>
										<td class="ocultar-celda col-md-1 td-modal"></td>
										<td class="col-md-2 td-modal2">
											Ordenar por
											<div class="">
												<div class="" id="title"></div>
												<div class="" v-if="destino == 25 || destino == 22 || destino == 33 || destino == 34">
												<!-- <div class="" v-if="destino == 19 || destino == 25 || destino == 22 || destino == 33 || destino == 34"> -->
													<select v-model="filtroModel" @change="filtrar()" class="vs-ordenar filter-order" style="position: inherit; z-index: 1;">
														<option :value="filtro.value" v-for="filtro in filtrosComplet" :key="filtro.value">
															{{ filtro.label }}
														</option>
													</select>
												</div>
												<div class="filter-order" v-else>
													<select v-model="filtroModel" @change="filtrar()" class="vs-ordenar filter-order" style="position: inherit; z-index: 1;">
														<option :value="filtro.value" v-for="filtro in filtros" :key="filtro.value">{{ filtro.label }}</option>
													</select>
												</div>
											</div>
										</td>
										<td class="ocultar-celda col-md-2 td-modal2">Acciones</td>
										<td class="ocultar-celda col-md-1 td-modal">Promociones</td>
										<td class="ocultar-celda col-md-1 td-modal">Producto</td>
									</tr>
								</table>
								<div class="" v-for="(item, index) in items" :key="item.objectID" style="">
									<Productos :item="item" :destino="destino" :filtroName="filtroName" :filtroId="filtroId" class="" :user="caso.solicitante" :ingreso="ingreso" :ministraciones="usoMinistraciones" :plazoMinistracion="plazoContruccion">
								
									 <template slot="mejora" v-if="(destino == 19 || destino == 25 || destino == 22 || destino == 33 || destino == 34) && item.MejoraMensualidad">
											<td class="td-modal">Ahorro Mensual:</td>
											<td>${{ numberFormat(item.MejoraMensualidad) }}</td>
											<td class="td-modal">Ahorro Total:</td>
											<td>${{ numberFormat(item.AhorroTotal > 0 ? item.AhorroTotal : 0) }}</td>
										</template>
										<template slot="cofinavit" v-if=" caso.programa == 4 && item.GastosInicialesCofinavit">
											<td class="td-modal">Gastos Iniciales con Cofinanciamiento Infonavit:</td>
											<td>${{ numberFormat(item.GastosInicialesCofinavit) }}</td>
										</template>
										<template slot="gastos" v-else>
											<td class="td-modal">Gastos Iniciales:</td>
											<td>${{ numberFormat(item.GastosIniciales) }}</td>
										</template>
										<!-- SLOT: ACTION BUTTONS -->
										<template slot="action-buttons">
											<td class="col-md-1">
												<div class="item-view-secondary-action-btn cursor-pointer">
													<button class="btn-seleccionar" v-bind:class="{ 'active': item.Selected }" :disabled="item.Selected" v-if="item.BancoId != 33" @click="solicitar(item)">{{ item.Selected ? 'Seleccionado' : 'Seleccionar' }}</button>
												</div>
											</td>
										</template>
										<template slot="action-buttons-2">
											<div class="">
												<div class="col-md-6">
													<vx-tooltip :text="comprobarFavorito(item) && isLimitFavoritos ? 'Solo se pueden marcar 5 favoritos como máximo' : 'Favoritos'">
														<button class="favoritos" :disabled="isLimitFavoritos" color="primary" type="line" @click.prevent="agregarFavorito(item)" v-if="comprobarFavorito(item)">
															<svg class="heart" v-bind:class="{ 'disabled': isLimitFavoritos }" :svgClasses="[{ 'fill-current': !comprobarFavorito(item) }]">
																<path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)" />
															</svg>
														</button>
														<button class="favoritos" icon-pack="feather" @click.prevent="quitarFavorito(item)" v-else>
															<feather-icon icon="HeartIcon" :svgClasses="[{ 'text-danger fill-current': !comprobarFavorito(item) }, 'h-8 w-8']" />
														</button>
													</vx-tooltip>
												</div>
											</div>
										</template>
									</Productos>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
						<div class="pro-card">
							<div>
								<div class="tab-body-2" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" v-if="caso.solicitudes.length">
									<div class="col-md-4" v-for="item in caso.solicitudes" :key="item.Id">
										<Solicitados :solicitados="item" :nacionalidadSol="nacionalidad" :isActiveCoacreditado="isActiveCoacreditado" :precalificacionTipo = "caso.PrecalificacionTipo">
											<template slot="action-favs">
												<div class="trash-pro">
													<button type="filled" class="favoritos" @click="quitar(item)">
														<svg class="trash">
															<g id="trash">
																<path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
																<path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
																<path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
																<path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
																<path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
																<path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
															</g>
														</svg>
													</button>
												</div>
											</template>
										</Solicitados>
									</div>
								</div>
								<div v-else>
									<span class="span-placeholder">No hay productos elegidos</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--termina el acordeom-->
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import Productos from '@/views/Caso/Productos.vue'
import Favoritos from '@/views/Caso/Favoritos.vue'
import Solicitados from '@/views/Caso/Solicitados.vue'
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Automotriz from '@/views/Destinos/Automotriz.vue';
import Adquisicion from '@/views/Destinos/Adquisicion.vue'
import Mejora from '@/views/Destinos/Mejora.vue'
import AdquisicionCofi from '@/views/Destinos/AdquisicionCofi.vue'
import AdquisicionAliados from '@/views/Destinos/AdquisicionAliados.vue'
import Terreno from '@/views/Destinos/Terreno.vue'
import TerrenoConstruccion from '@/views/Destinos/TerrenoConstruccion.vue'
import TerrenoProgramas from '@/views/Destinos/TerrenoProgrmas.vue'
export default {
	components: {
		vSelect,
		Productos,
		Favoritos,
		swiper,
		swiperSlide,
		Automotriz,
		Adquisicion,
		Mejora,
		AdquisicionCofi,
		AdquisicionAliados,
		Terreno,
		TerrenoConstruccion,
		Solicitados,
		TerrenoProgramas,
	},
	data() {
		return {
			autosDestinos: [],
			porcentajeActivaCotiza: true,
			productoTipoList: [],
			// aportacion voluntaria
			hasAportacion: "No",
            plazosList: [],
            prepagoList: [],
			tipoPrepago: '',
            aportacionVoluntaria: '',
            tipoPlazo: '',
            numeroAportacion: '',
			helperCantidadAportacion: '',
			// aportacion voluntaria

			factorPorcentualSantander: {
				aforoTipoA: [ // 5-50% 
					{ plazo: 10, porcentaje: 1.1706 },
					{ plazo: 15, porcentaje: 1.6414 },
					{ plazo: 20, porcentaje: 2.0394 },
				],
				aforoTipoB: [ // 55-70% 
					{ plazo: 10, porcentaje: 1.3439 },
					{ plazo: 15, porcentaje: 1.8819 },
					{ plazo: 20, porcentaje: 2.3396 },
				],
				aforoTipoC: [ // 75%  
					{ plazo: 10, porcentaje: 1.5161 },
					{ plazo: 15, porcentaje: 2.1227 },
					{ plazo: 20, porcentaje: 2.6401 },
				],
				aforoTipoD: [ // 80% 
					{ plazo: 10, porcentaje: 1.4747 },
					{ plazo: 15, porcentaje: 2.0654 },
					{ plazo: 20, porcentaje: 2.5683 },
				],
				aforoTipoE: [ // 85% 
					{ plazo: 10, porcentaje: 1.6387},
					{ plazo: 15, porcentaje: 2.2947 },
					{ plazo: 20, porcentaje: 2.8542 },
				],
				aforoTipoF: [ // 90% 
					{ plazo: 10, porcentaje: 1.9051 },
					{ plazo: 15, porcentaje: 2.6924 },
					{ plazo: 20, porcentaje: 3.4094 },
				],
			},

			swiperOption: {
				pagination: {
					el: '.swiper-pagination',
				},
			},
			socData: '',
			urlSolicitud: '',
			popupSolicitud: false,
			items: [],
			key: '',
			estatus: [],
			destinos: [],
			programas: [],
			id: '',
			// header: {
			// "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
			// },
			SocGuid: '',
			BrokerId:'',
			IdLiga: '',
			url: '',
      nacionalidad:'',
			caso: {
				valorInmueble: '',
				saldoInsoluto: '',
				plazos: [],
				destino: '',
				esquemas: [],
				pagoMensualActual: '',
				plazoRestante: '',
				ejecutivo: '',
				estado: '',
				estatus: '',
				favoritos: [],
				solicitudes: [],
				montoSolicitado: '',
				programa: '',
				email: '',
				bancos: [],
				inmuebleNuevo: true,
				estado: '',
				plaza: '',
				solicitante: '',
				tasa: '',
				ssv: '',
				creditoInf: '',
				gastosNotarial:6,
				valorProyecto: 0,
				valorTerreno: 0,
				montoCredito: 0,
				curp: '', 
				nombre: '',
				appPaterno: '',
				appMaterno: '',
				telefono: '',
				Segnombre: '',
				PrecalificacionTipo: '',
				aniosACotizar: '',
				VersionOrigen: '',
			},
			bancosAuto: [
				{ label: "HSBC", value: 6 },
				{ label: "SCOTIABANK", value: 11 },
				{ label: "AFIRME", value: 12 },
				{ label: "HEY, BANCO", value: 14 },
			],
			esquemas: [
				{ label: 'Pago Fijo', value: '2' },
				{ label: 'Pago creciente', value: '1' },
			],
			plazos: [
				{ label: '20 Años', value: '20' },
				{ label: '15 Años', value: '15' },
				{ label: '10 Años', value: '10' },
				{ label: '5 Años', value: '5' },
				{ label: '25 Años', value: '20' },
				{ label: '19 Años', value: '19' },
				{ label: '18 Años', value: '18' },
				{ label: '17 Años', value: '17' },
				{ label: '16 Años', value: '16' },
				{ label: '14 Años', value: '14' },
				{ label: '13 Años', value: '13' },
				{ label: '12 Años', value: '12' },
				{ label: '11 Años', value: '11' },
				{ label: '9 Años', value: '9' },
				{ label: '8 Años', value: '8' },
				{ label: '7 Años', value: '7' },
				{ label: '6 Años', value: '6' },
				{ label: '4 Años', value: '4' },
				{ label: '3 Años', value: '3' },
				{ label: '2 Años', value: '2' },
				{ label: '1 Años', value: '1' },
			],
			 gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
            ],
			ejecutivos: [],
			bancosEjecutivo: [],
			bancos: [],
			filtroModel: 1,
			filtros: [
				{ label: 'Mensualidad Inicial', value: '1' },
				{ label: 'Alfabético', value: '9' },
				{ label: 'Cat', value: '2' },
				{ label: 'Tasa', value: '3' },
				{ label: 'Pago total', value: '4' },
				{ label: 'Con promociones', value: '5' },
				{ label: 'Ingreso Mínimo', value: '6' },
			],
			filtrosComplet: [
				{ label: 'Mensualidad Inicial', value: '1' },
				{ label: 'Alfabético', value: '9' },
				{ label: 'Cat', value: '2' },
				{ label: 'Tasa', value: '3' },
				{ label: 'Pago total', value: '4' },
				{ label: 'Con promociones', value: '5' },
				{ label: 'Ingreso Mínimo', value: '6' },
				{ label: 'Mayor ahorro total', value: '7' },
				{ label: 'Mayor ahorro mensual', value: '8' },
			],
			estados: [],
			plazas: [],
			tasas: [],
			destino: '',
			filtroName: '',
			filtroId: '',
			ingreso: {},
			otro: 0,
			muestraFavoritos: false,
			itemsIons: [],
			isActiveContentCiti: false,
			isActiveCoacreditado: false,
			state_data: 1,
			ExtrasVentaDigital:'',
			ligaCliente : false,
			curp: '',
			message :'',
			logEstado:'',
			logPlaza:'',
			logEsNuevo:'',
			CoAcreditado :'',
			ValorInmuebleKredi:'',
			EngancheKredi:'',
			PorcentajeEngancheKredi:'',
			PlazosKredi:'',
			FinanciamientoKredi:'',
			ComisionAperturaKredi:'',
		    EstadoKredi:'',
			GastosNotarialesKredi:'',
			PlazosKrediInicio: [],
			ValorViendaCofi :'',
			montoCreditoCofi : '',
			ssvCofi : '',
			saldoInfo : '',
			programaCofi : '',
			bandera : false,
			esquemasCaso: '',
			Programa: '',
			// MINISTRACIONES EN COMPARADOR - JORGE SEP 2023
			usoMinistraciones: 'No',
			plazoContruccion: '',
			plazoMinistraciones: [
				{label: '-- Seleccione --', value: ''},
				{label: '3', value: '3'},
				{label: '4', value: '4'},
				{label: '5', value: '5'},
				{label: '6', value: '6'},
				{label: '7', value: '7'},
				{label: '8', value: '8'},
				{label: '9', value: '9'},
				{label: '10', value: '10'},
				{label: '11', value: '11'},
				{label: '12', value: '12'},
				{label: '13', value: '13'},
				{label: '14', value: '14'},
				{label: '15', value: '15'},
				{label: '16', value: '16'},
				{label: '17', value: '17'},
				{label: '18', value: '18'}
			],
			periodo: '',
			periodoMinistraciones: [
			{label: '-- Seleccione --', value: ''},
			/* {label : 'Mensual', value: 1},
			{label : 'Bimestral', value: 2},
			{label : 'Trimestral', value: 3},
			{label : 'Cuatrimestral', value: 4},
			{label : 'Semestral', value: 6},
			{label : 'Anual', value: 12} */
			],
			campos: '',			
			camposID: {
				name: []
			},
			banderaSave: false,
			
      PorcentajeMaximo: 0,
			isLimitFavoritos: false
		}
	},
	mounted() {
		//alert(localStorage.getItem('validaCamposVacios'))
		if (localStorage.getItem('regresoImprimirFavs')) {
			// alert('cambio')
			let elemento = document.getElementById('home-tab')
			elemento.classList.remove('active')
			let elementoTabCont = document.getElementById('home')
			elementoTabCont.classList.remove('show')
			elementoTabCont.classList.remove('active')
			elemento.setAttribute('aria-selected', 'false')

			let elemento2 = document.getElementById('profile-tab')
			elemento2.setAttribute('aria-selected', 'true')
			elemento2.classList.add('active')
			let elementoTabCont2 = document.getElementById('profile')
			elementoTabCont2.classList.add('show')
			elementoTabCont2.classList.add('active')
			this.muestraFavoritos = true
			setTimeout(() => localStorage.removeItem('regresoImprimirFavs'), 5000)
		}
		this.$vs.loading({
			container: '#cotizacion',
			scale: 0.6,
		})
		this.id = this.$route.params.id
		this.socData = localStorage.getItem('socData')		
		this.getApikey()
		// this.getDestinos()
		this.getDestinosAuto()
		this.getProductoTipos();
		this.getEstatus()
		this.getProgramas()
		this.getBancos()
		this.listaSupervisados()
		this.getEstados()
		this.getTasas()
		this.dameCaso()
		this.cotiza()
		localStorage.removeItem('vistaNuevaCotizacion')
		this.DameCredenciales()

        this.getPrepagoList()
        this.getPlazoAportacionList()
		this.actualizaCampos()		
		this.getPrepagoList()
		this.getPlazoAportacionList()

     this.getDataDestino()
	},
	watch: {
		'caso.PrecalificacionTipo': function(newValue, oldValue) {
			//  console.log( 'productoTipo', newValue );
			this.getDestinos(newValue);
		},
		// INFO: Sin dependencia de plazos de credito
		numeroAportacion: function(newValue, oldValue) {
			// console.log( 'newValue: ', newValue, 'oldValue: ', oldValue, this.numeroAporacion );
			if (this.hasAportacion == "Si") {
				
				// verifico si es un numero
				let regex = /^[0-9]*$/;
				
				if (!regex.test(newValue)) {
					this.numeroAportacion = oldValue;
					// console.log( 'er', this.numeroAportacion );
				}
				
				// valida el plazo de credito y el plazo de aportacion
				if (this.caso.plazos.length > 0 && this.tipoPlazo != '') {
					
					if (!this.numeroAportacion) {
						this.helperCantidadAportacion = `Escriba un valor correcto`;
						return;
					}
					
					// plazo mas grande
					let plazo = Math.max(...this.caso.plazos);
					let periodo = this.tipoPlazo;
					let limiteAportacion = 0;
					
					// calcula el limite
					if (plazo > 0 && periodo > 0) {
						switch (periodo) {
							case 1:
								// mensual
								limiteAportacion = 12 * plazo;
								break;
							case 2:
								// bimestral
								limiteAportacion = (12 / 2) * plazo;
								break;
							case 3:
								// semestral
								limiteAportacion = (12 / 6) * plazo;
								break;
							case 4:
								// anual
								limiteAportacion = plazo;
								break;
						}
					}
					
					// limita el valor
					if ( newValue > limiteAportacion) {
						this.numeroAportacion = '';
					}
					
					// mensaje
					this.helperCantidadAportacion = `Tú límite de aportaciones es ${ limiteAportacion }`;
					return;
				}
				
				// console.log( 'falta credito/plaza', typeof(this.numeroAportacion), this.numeroAportacion );
				this.helperCantidadAportacion = `Definir plazo de aportación y plazo a cotizar`;
				this.numeroAportacion = ''; // reinicia el valor
			}
    	},
		hasAportacion: function(newValue, oldValue) {
			// reinicia el valor de los campos de aportacion
			if (newValue == "No") {
				this.tipoPrepago = '';
				this.aportacionVoluntaria = '';
				this.tipoPlazo = '';
				this.numeroAportacion = '';
				this.helperCantidadAportacion = '';
			}
		},
		// destino: function(n, o) {
		// 	if (this.autosDestinos.includes(n)) {
		// 		// this.getBancosAutoDestino();
		// 	}
		// }	
	},
	computed: {
		destinoNombreSolo: function () {
			for (var i = 0; i < this.destinos.length; i++) {
				if (this.destinos[i].Id == this.destino) {
					return this.destinos[i].Nombre
				}
			}
		},
		validateForm() {
			return this.curp != '' && this.curp.length == 18
		},
		validaPlaza(){			

			if (this.usoMinistraciones == 'Si') {				
				return this.caso.plaza != '' && 
				   this.caso.plaza != null &&
				   this.validateAportacion && this.banderaSave && 
				   this.porcentajeActivaCotiza
			}else{
				return this.caso.plaza != '' && 
				   this.caso.plaza != null &&
				   this.validateAportacion && 
				   this.porcentajeActivaCotiza
			}
		},
		validateAportacion() {
			if (this.hasAportacion == "Si") {
				return this.tipoPrepago != '' &&
					   this.aportacionVoluntaria != '' &&
					   this.tipoPlazo != '' &&
					   this.numeroAportacion != ''
			}
			return true;
		},		
	},
	methods: {
		cambiarBancosAutos() {
			if (this.autosDestinos.includes(this.destino)) {
				this.getBancosAutoDestino();
			}
		},
		getBancosAutoDestino() {
			let self = this;
			let objRequestBancosAutoDestino = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosAutoDestino',
				objCaso: {
					Destino: this.destino,
				},
			}
			this.$axios
			.post('/', objRequestBancosAutoDestino, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
			.then((response) => {
				if (response.data.intEstatus == 0) {

					this.bancos = [];
					this.caso.bancos = [];
					response.data.objContenido.forEach(banco => {
						this.bancos.push({ label: banco.Nombre, value: banco.Id })
					});
					this.caso.bancos = this.bancos;

					// console.log( 'getBancosAutoDestino', this.caso.bancos );
					// console.log( 'getBancosAutoDestino', this.bancos );

				} else {
					this.$vs.notify({
						title: 'Ocurrio un error en ListaBancosAutoDestino',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}
			})
			.catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error en ListaBancosAutoDestino',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			})
		},
		getDestinosAuto() {
			let self = this
			let objRequestListaDestinosAuto = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinosAuto',
			}
			this.$axios.post('/', objRequestListaDestinosAuto, {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						let objAutos = response.data.objContenido;
						objAutos.forEach(objAuto => {
							this.autosDestinos.push(objAuto.Id);
						});
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaDestinosAuto',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right'
						})
					}
				}
			).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position:'top-right'
				})
			})
		},
		getProductoTipos() {
			let self = this;
			let objRequestProductoTipos = {
				strApiKey: this.key,
				strMetodo: 'DameProductoTipos',
			}
				
			this.$axios.post(
				'/', 
				objRequestProductoTipos, 
				{ headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
			).then((response) => {
				if (response.data.intEstatus == 0) {
					let productoList = response.data.objContenido;
					// retirar pyme, tarjeta, personal
					productoList.splice(1,1);
					productoList.splice(2,2);
					productoList[1].Nombre = "Auto";
					this.productoTipoList = productoList;
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error en DameProductoTipos',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}
			}).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error en DameProductoTipos',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			})
		},
		//Ministraciones
		updatePeriodo(){		
			this.periodo = ''
			this.campos = ''
			document.getElementById('alert').style.display = 'none'

			if (this.plazoContruccion < 5) {
				this. periodoMinistraciones =  [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
				]
			}else if(this.plazoContruccion < 7){
				this.periodoMinistraciones= [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},					
				]
			}else if(this.plazoContruccion < 13){
				this.periodoMinistraciones = [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},
					{label : 'Semestral', value: 6},
					
				]
			}else{
				this.periodoMinistraciones = [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},
					{label : 'Semestral', value: 6},
					{label : 'Anual', value: 12}
				]
			}
		},

		updateCampos(){
			this.campos = ''
			this.banderaSave = false
			document.getElementById('alert').style.display = 'none'
			if (this.plazoContruccion && this.periodo) {
				this.campos = Math.floor(this.plazoContruccion / this.periodo)				
			}			

		},
		removeInputs(){
			/*for (let i = 1; i <= this.campos; i++) {
				document.getElementById("inputFormRow").remove();
			}*/
			document.getElementById("inputFormRow").remove();
		},
		calculaPorcentaje(){			
			this.banderaSave = false;
			document.getElementById('alert').style.display = 'none'					
			var calcular = 0;
			for (let p = 1; p <= this.campos; p++) {				
				calcular += parseInt(this.camposID.name[p])				
			}			
			if (calcular != 100) {
				document.getElementById('alert').style.display = 'block'
			}else{
				this.banderaSave = true;				
			}

		},
		actualizaCampos(){			
			for (let p = 1; p <= 18; p++) {
				this.camposID.name[p] = 0				
			}
		},
		SoloNumero: function(evt){
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		dameMinistracion(){
			let self = this
			let objRequestMinistracion = {
				strApiKey: this.key,
				strMetodo: 'dameMinistracion',
				objCaso : {
					idCaso : this.id
				}
			}
			this.$axios.post('/',objRequestMinistracion, { headers: { 'Content-Type':'application/x-www-form-urlencoded; charset = UTF-8' } } )
			.then((response) => {

				if (response.data.intEstatus == 0) {
					let objMinistracion = response.data.objContenido
					this.usoMinistraciones = 'Si'
					this.plazoContruccion = objMinistracion.Plazo
					
					if (this.plazoContruccion < 5) {
						this. periodoMinistraciones =  [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
						]
					}else if(this.plazoContruccion < 7){
						this.periodoMinistraciones= [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
							{label : 'Cuatrimestral', value: 4},					
						]
					}else if(this.plazoContruccion < 13){
						this.periodoMinistraciones = [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
							{label : 'Cuatrimestral', value: 4},
							{label : 'Semestral', value: 6},
							
						]
					}else{
						this.periodoMinistraciones = [
							{label: '-- Seleccione --', value: ''},
							{label : 'Mensual', value: 1},
							{label : 'Bimestral', value: 2},
							{label : 'Trimestral', value: 3},
							{label : 'Cuatrimestral', value: 4},
							{label : 'Semestral', value: 6},
							{label : 'Anual', value: 12}
						]
					}
					
					this.periodo = objMinistracion.Periodo
					this.campos = Math.floor(this.plazoContruccion / this.periodo)					

					let arrayPorcentajes = objMinistracion.Porcentajes.split(',')
					var calcular = 0;

					/*for (let index = 0; index < arrayPorcentajes.length; index++) {
						console.log("["+index+"]"+arrayPorcentajes[index])		
										
					}*/
					for (let i = 1; i <= this.campos; i++) {
						this.camposID.name[i] = arrayPorcentajes[i-1];						
						calcular += parseInt(this.camposID.name[i])												
					}

					// valida el porcentaje en cada uno de los campos				
					if (calcular != 100) {
						document.getElementById('alert').style.display = 'block'
					}else{
						this.banderaSave = true;						
					}

				}else{
					console.log("Sin ministraciones")
				}

			}).catch(function (error){
					self.$vs.notify({
					title: 'Ocurrio un error en DameMinistracion',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			})
			
		},

		// aportacion voluntaria
        getPrepagoList() {
			let self = this
			let objRequestPrepagoList = {
				strApiKey: this.key,
				strMetodo: 'ListaAportacionPrepagos',
			};

			this.$axios
				.post(
                        '/', 
                        objRequestPrepagoList, 
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
                    )
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.prepagoList = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaAportacionPrepagos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
        },
        getPlazoAportacionList() {
			let self = this
			let objRequestPlazoAportacionList = {
				strApiKey: this.key,
				strMetodo: 'ListaAportacionPlazos',
			};

			this.$axios
				.post(
                        '/', 
                        objRequestPlazoAportacionList, 
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
                    )
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.plazosList = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaAportacionPlazos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
        },
        checkApoVol() {
            this.aportacionVoluntaria = this.aportacionVoluntaria.replace(/,/g,"")
            const regex = /^(\d{1,8})(\.\d{0,2})?$/  
            const matches = regex.test(this.aportacionVoluntaria);
            if (matches === true){
            this.aportacionVoluntaria = this.aportacionVoluntaria
            } else{
                this.aportacionVoluntaria = this.aportacionVoluntaria.replace(/.$/, '')
            }
        },
        setFormatApoVol() {
			if (this.aportacionVoluntaria == '') { 
                this.aportacionVoluntaria = 0 
            }
			this.aportacionVoluntaria = parseFloat(this.aportacionVoluntaria).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.aportacionVoluntaria = this.aportacionVoluntaria.toString().replace(exp, rep)
        },
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		setPlazoAportacion() {
			// al cambiar el plazo se reinicia el numero de aportaciones
			this.numeroAportacion = '';
			this.helperCantidadAportacion = '';
		},
		// aportacion voluntaria

		calculaFactorSantander(aforo, plazo, credito) {

			let aforoMultiplo = Math.ceil(aforo / 5) * 5; // obtiene multiplo de 5 mas cercano, si es multiplo exacto queda igual
			let factor;
			let montoAdicional = 0;

			if (aforoMultiplo >= 5 && aforoMultiplo <= 50) {
				factor = this.factorPorcentualSantander.aforoTipoA.find((item) => item.plazo == plazo);
			} else if (aforoMultiplo >= 55 && aforoMultiplo <= 70) {
				factor = this.factorPorcentualSantander.aforoTipoB.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo == 75) {
				factor = this.factorPorcentualSantander.aforoTipoC.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo == 80) {  
				factor = this.factorPorcentualSantander.aforoTipoD.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo == 85) {
				factor = this.factorPorcentualSantander.aforoTipoE.find((item) => item.plazo == plazo);
			}else if (aforoMultiplo >= 90 && aforoMultiplo <= 100 ) {
				factor = this.factorPorcentualSantander.aforoTipoF.find((item) => item.plazo == plazo);
			}

			// verifica si encontro algun plazo valido
			if (!factor) {
				montoAdicional = 0;
				// console.log( 'No se encontro plazo', montoAdicional );
				return montoAdicional;
			}

			montoAdicional = ((factor.porcentaje / 100) * credito).toFixed(2);
			montoAdicional = Number(montoAdicional);
			// console.log( 'MontoData: ', factor.porcentaje, credito, plazo,  montoAdicional );
			return montoAdicional;
		},

		limpiarBancos() {
			this.caso.bancos = []
		},
		seleccionarTodos() {
			this.caso.bancos = this.bancos
		},
		// cambiaBancosAuto(e, origen) {
		// 	let EsquemaCasoCot = [];
		// 	this.bancos = [];
		// 	this.caso.plazos = [];
			
		// 	for (var i = 0; i < e.plazosACotizar.length; i++) {
		// 		if (typeof e.plazosACotizar[i] == 'object') {
		// 			this.caso.plazos.push(parseInt(e.plazosACotizar[i].value));
		// 		} else {
		// 			this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
		// 		}
		// 	}
		// 	for (var i = 0; i < e.esquemasACotizar.length; i++) {
		// 		EsquemaCasoCot.push(parseInt(e.esquemasACotizar[i].value));
		// 	}

		// 	// Se reinicia
		// 	this.bancos = this.bancosAuto;
		// 	this.caso.bancos = this.bancos;
		// },
		cambiaBancos(e, origen) {
			var EsquemaCasoCot = []

			if (origen == 'destino') {
				this.bancos = []
				//this.caso.esquemas = []
				this.caso.destino = e.Destino

				for (var i = 0; i < e.EsquemasCaso.length; i++) {
					EsquemaCasoCot.push(parseInt(e.EsquemasCaso[i].value))
				}
			} else {
				this.bancos = []
				this.caso.plazos = []
				//this.caso.esquemas = []

				for (var i = 0; i < e.plazosACotizar.length; i++) {
					if (typeof e.plazosACotizar[i] == 'object') {
						this.caso.plazos.push(parseInt(e.plazosACotizar[i].value))
					} else {
						this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
					}
				}
				for (var i = 0; i < e.esquemasACotizar.length; i++) {
					EsquemaCasoCot.push(parseInt(e.esquemasACotizar[i].value))
				}
			}

			let self = this
			let objRequestDamePlazos = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosPlazos',
				objCaso: {
					Destino: this.destino,
					Esquema: EsquemaCasoCot,
					Plazos: this.caso.plazos,
				},
			}
			this.$axios
				.post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						//  if(this.caso.plazos.length==1){
						/*if(this.caso.plazos[0]=='25'){
									this.caso.bancos=[]

										this.caso.bancos.push({ label: 'BANORTE', value: 4 })
										this.caso.bancos.push({ label: 'HSBC', value: 6 })
									
										this.bancos.push({ label: 'BANORTE', value: 4 })
										this.bancos.push({ label: 'HSBC', value: 6 })
										
								}else{
								
										let bancos = response.data.objContenido
										for (var i = 0; i < bancos.length; i++) {
											this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
										}
											this.bancos.push({ label: 'KREDI', value: 33 })
													//this.caso.bancos = this.bancosEjecutivo //cambio bancos
													this.caso.bancos = this.bancos 
							}*/

						// }else{

						let bancos = response.data.objContenido
						for (var i = 0; i < bancos.length; i++) {
							this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
						}
						this.bancos.push({ label: 'KREDI', value: 33 })
						//this.caso.bancos = this.bancosEjecutivo //cambio bancos
						this.caso.bancos = this.bancos
						//  }


						/**
						* Solo se mostrara el banco banorte si el plazo es 21,22,24 sin combinar con otros plazos
						*/
						// console.log( 'pruebas' );
						let checkPlazos = [];
						checkPlazos = this.caso.plazos;
						if (checkPlazos.length > 0 && checkPlazos.length < 4) { // solo puede tener 3 plazos max
							let findPlazo = this.caso.plazos.find(item => (item != 21 && item != 22 && item != 24));

							if (!findPlazo) { // si no se encontro otro plazo diferente a 21,22,24
								this.caso.bancos = [];
								this.bancos = [];
								this.caso.bancos.push({ label: 'BANORTE', value: 4 });
								this.bancos.push({ label: 'BANORTE', value: 4 });
							}
						}

					} else if (response.data.intEstatus == 1) {
						this.caso.bancos = []
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFinancieras',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error en DamePlazos',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameBancosAuto(){ // no se usa

			if (this.caso.PrecalificacionTipo == 3) {
				let self = this
				let objRequestDamePlazos = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosAutos'
				}
				this.$axios
				.post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
					this.bancos = [];
					for (var i = 0; i < response.data.objContenido.length; i++) {
						this.bancos.push({ label: response.data.objContenido[i].Nombre, value: response.data.objContenido[i].Id })
					}
					this.caso.bancos = this.bancos
									
					}else {
					this.$vs.notify({
						title: 'Ocurrio un error en ListaBancosAutos',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right',
					})
				})
			}      
		},
		pruebaIon(plazo, destinoId) {
			let self = this
			let destino = ''
			let GastosInicialCalculado = ''
			let NombreProducto = ''
			let amount = ''
			let respuestaData = ''
			let calculoAforo = ''

			calculoAforo = (this.caso.montoSolicitado.replace(/,/g, '') * 100) / this.caso.valorInmueble.replace(/,/g, '')

			switch (destinoId) {
				case 1:
					destino = 'REAL_ESTATE'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Adquisición Plus'
					break
				case 3:
					destino = 'LIQUIDITY'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.03
					NombreProducto = 'ION Liquidez Plus'
					break
				case 5:
					destino = 'LAND'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Adquisición de Terreno Plus'
					break
				case 37:
					destino = 'OFFICES_AND_STORES'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Oficinas y Locales Plus'
					break
			}
			var objGeneraCodigo = {
				person_type: 'NATURAL', //valor fijo
				product: destino, //producto
				required_amount: { amount: this.caso.montoSolicitado.replace(/,/g, ''), currency: 'MXN' }, // cantidad requerida
				monthly_income: { amount: 50000000, currency: 'MXN' }, //ingreso mensual valor fijo
				real_estate_value: { amount: this.caso.valorInmueble.replace(/,/g, ''), currency: 'MXN' }, //valor inmobiliario
				term: parseInt(plazo),
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoIon.php', objGeneraCodigo, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					respuestaData = JSON.parse(response.data.objRespuesta)

					if (respuestaData.message == 'Internal server error') {
						this.$vs.loading.close('#cotizacion > .con-vs-loading')

						self.$vs.notify({
							title: 'Internal server error ION',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					} else {
						var respuestaIon = JSON.parse(response.data.objRespuesta)
						let itemsIon = {}
						let items = []
						let cantidadCotiza = items.length
						itemsIon.Cat = parseFloat(respuestaIon.data.cat)
						itemsIon.Mensualidad = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.Tasa = parseFloat(respuestaIon.data.interest_rate)
						itemsIon.IngresoMinimo = parseFloat(respuestaIon.data.minimum_income) ///parseFloat(respuestaIon.data.monthly_payment.amount)/0.33
						itemsIon.Enganche = parseFloat(this.caso.valorInmueble.replace(/,/g, '') - this.caso.montoSolicitado.replace(/,/g, ''))
						itemsIon.GastosIniciales = parseFloat(respuestaIon.data.initial_expenses) ///parseFloat(GastosInicialCalculado)
						itemsIon.Aforo = calculoAforo
						itemsIon.Plazo = respuestaIon.data.term * 12
						itemsIon.PagoTotal = respuestaIon.data.total_payment
						itemsIon.MensualidadPromedio = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.MensualidadInicial = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.BancoId = 21
						itemsIon.Banco = 'ION'
						itemsIon.Producto = NombreProducto
						itemsIon.Esquema = 2
						itemsIon.Logo = 'LogoIon_130622.png'
						itemsIon.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsIon.JsonAmortizacion = respuestaIon.data.amortization_table.raw_data
						itemsIon.IdIon = respuestaIon.data.id
						this.items.push(itemsIon)

						// console.log({ items })
						//itemsIon.push(parseInt(this.caso.esquemas[i].value))

						this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
						this.$vs.loading.close('#cotizacion > .con-vs-loading')
					}
					//console.log(respuestaIon.data.amortization_table.raw_data)
					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en apiIon',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		RegistraCasoKredi(destinoId,ValorInmuebleKredi,EngancheKredi,PorcentajeEngancheKredi,PlazosKredi,FinanciamientoKredi,ComisionAperturaKredi,EstadoKredi,GastosNotarialesKredi){
			
			let self = this
			let producto = ''
			let respuestaKredi = ''


			if(destinoId==1){
				producto=1
			}else if(destinoId==19){
				producto=2
			}

			var product_application = {
				product_id: producto,
				answers: {
					"fecha": "02/06/2023",
					"valor_del_inmueble": ValorInmuebleKredi,
					"enganche": EngancheKredi,
					"porcentaje_de_enganche": PorcentajeEngancheKredi,
					"plazo_en_anos": PlazosKredi,
					"financiamiento_de_comision_por_apertura": FinanciamientoKredi,
					"porcentaje_comision_por_apertura": ComisionAperturaKredi,
				
					"estado_en_donde_se_encuentra_la_propiedad": EstadoKredi,
					"porcentaje_gastos_notariales":"null"

				},
				"async": false,
				"customer_id": "",
				"external_id": "",
				"dry_run": true
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredi.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {

				

					respuestaKredi = JSON.parse(response.data.objRespuesta)
		
					
						let itemsKredi = {}
						let items = []
						let cantidadCotiza = items.length
						itemsKredi.Monto= parseFloat(respuestaKredi.data.attributes.answers.monto_del_credito_solicitado)
						itemsKredi.Cat = parseFloat(respuestaKredi.data.attributes.cat * 100)
						itemsKredi.Mensualidad = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.Tasa = parseFloat(respuestaKredi.data.attributes.answers.tasa_de_interes * 100 )
						itemsKredi.IngresoMinimo = parseFloat(respuestaKredi.data.attributes.minimum_income_required) 
						
						itemsKredi.GastosIniciales = parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo + respuestaKredi.data.attributes.answers.gastos_notariales_aproximados + respuestaKredi.data.attributes.answers.comision_por_apertura) 
						itemsKredi.Avaluo= parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo)
						itemsKredi.ComisionApertura= parseFloat(respuestaKredi.data.attributes.answers.comision_por_apertura)
						itemsKredi.GastosNotariales= parseFloat(respuestaKredi.data.attributes.answers.gastos_notariales_aproximados)
						
						itemsKredi.Aforo = respuestaKredi.data.attributes.answers.aforo * 100
						itemsKredi.Plazo = respuestaKredi.data.attributes.answers.plazo_en_meses
						itemsKredi.PagoTotal = 0
						itemsKredi.MensualidadPromedio = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.MensualidadInicial = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.BancoId = 33
						itemsKredi.Banco = 'KREDI'
						if(respuestaKredi.data.attributes.product_id==1){
							itemsKredi.Producto = 'KREDI ADQUISICIÓN DE VIVIENDA'
							itemsKredi.Enganche = parseFloat(respuestaKredi.data.attributes.hitch) 
						}else if(respuestaKredi.data.attributes.product_id==2){
							itemsKredi.Producto = 'KREDI MEJORA DE HIPOTECA ORIGEN VIVIENDA'
							itemsKredi.Enganche = 0
							itemsKredi.MejoraMensualidad=0
							itemsKredi.AhorroTotal =0
							itemsKredi.Monto= parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
              				itemsKredi.Aforo = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / parseFloat(this.caso.valorInmueble.replace(/,/g, ''))) * 100               
						}
						
						itemsKredi.Esquema = 2
						itemsKredi.Logo = 'kredi.png'
						//itemsKredi.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsKredi.JsonAmortizacion = respuestaKredi.data.attributes.last_amortization_table
						itemsKredi.IdIon = respuestaKredi.data.id
						this.items.push(itemsKredi)



				

					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

		mostrarFavoritos() {
			let element = document.getElementById('profile-tab')
			this.muestraFavoritos = element.classList.contains('active')
		},

		mostrarProgramas({ isCambioBancos = true }) {
			this.programas = []
			this.caso.programa = this.Programa
			this.usoMinistraciones = 'No'

			// 1: Sin Programa || 6: Apoyo Infonavit || 4: Cofinamiento Infonavit || 9: Fovisste || 10: Fovisste para Todos

			if (this.destino == 1 || this.destino == 5 || this.destino == 2 || this.destino == 15) {
				this.programas = this.todosProgramas
			} 
			else if (this.destino == 20) { // adquisicion de terreno y construción

				this.programas = []
				this.caso.programa = 1
				var auxProgramas = [1, 6]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}
			} 
			else if ( this.destino == 19 ) { // Mejora tu Hipoteca Origen Vivienda


				this.programas = []
				var auxProgramas = [1, 6, 4, 9]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}

				const orderPrograms = auxProgramas.map(x => {
					const exist = this.programas.find(programa => x === programa.Id)

					return exist && exist
				})

				this.programas = orderPrograms

			}
			else {				
				this.programas = []
				this.caso.programa = 1
				var auxProgramas = [1, 9, 4]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}				
			}

			if ( isCambioBancos ) {
				var Destino = this.destino			
				this.cambiaBancos(Destino, 'destino')					
			}

      this.getDataDestino()
		},

		cambia() {
			this.otro = this.otro + 1
		},
		numberFormat(number) {
			return number
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		dameSolicitante() {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.caso.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const status = response.data.intEstatus
					const dataSolicitante = response.data.objContenido

					if ( status ) {

						this.$vs.notify({
						title:'Ocurrio un error en DameSolicitante',
						text:response.data.strError,
						color:'danger',
						position:'top-right'
						})

					}

					this.state_data = dataSolicitante.StateData
					this.ingreso = dataSolicitante.Ingresos[0]
					this.caso.curp = dataSolicitante.Curp
					this.caso.appMaterno = dataSolicitante.ApellidoMaterno
					this.caso.appPaterno = dataSolicitante.ApellidoPaterno
					this.caso.telefono = dataSolicitante.TelefonoCelular
					this.caso.nombre = dataSolicitante.Nombres
					this.caso.Segnombre = dataSolicitante.SegundoNombre
          this.nacionalidad=dataSolicitante.Nacionalidad	
					this.IdLiga = dataSolicitante.AccreditedIdV3
					this.url = "https://tuoficina.socasesores.com/Tracking/AccreditedRequestData?AccreditedId="+this.IdLiga; 

					if(dataSolicitante.Curp != null && dataSolicitante.Curp != ""){
						this.caso.curp = dataSolicitante.Curp
						//this.caso.appMaterno = dataSolicitante.ApellidoMaterno
						//this.caso.appPaterno = dataSolicitante.ApellidoPaterno
						//this.caso.telefono = dataSolicitante.TelefonoCelular
						//this.caso.nombre = dataSolicitante.Nombres
						//this.caso.Segnombre = dataSolicitante.SegundoNombre						
					} else {
						this.caso.curp = 1
					}

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		RegistraAccreditedIdSolicitante(){
			let self = this
			let objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraAccreditedIdSolicitante',
				objSolicitante: {
					EMail: this.caso.solicitante,
					AccreditedIdV3: this.IdLiga
				},
			}

			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameCaso()
						this.openPopUp(false)
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Registra AccreditedId Solicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		RegistraCurpSolicitante(){
			let self = this

			let objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraCurpSolicitante',
				objSolicitante: {
					EMail: this.caso.solicitante,
					Curp: this.curp,
					//AccreditedIdV3: this.IdLiga
				},
			}

			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameSolicitante()
						this.RegistraAccreditedIdSolicitante()
						
						//this.dameCaso()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCurpSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},
		RegistraAccreditedIdSolicitante(){
			let self = this
			let objRequestRegistraSolicitante = {
				strApiKey: this.key,
				strMetodo: 'RegistraAccreditedIdSolicitante',
				objSolicitante: {
					EMail: this.caso.solicitante,
					AccreditedIdV3: this.IdLiga
				},
			}

			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameCaso()
						this.openPopUp(false)
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Registra AccreditedId Solicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		quitar(item) {
			let self = this
			var objRequestSolicitaCaso = {
				strApiKey: this.key,
				strMetodo: 'BorrarSolicita',
				intVariante: item.VarianteId,
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestSolicitaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: 'Eliminado exitosamente',
							position: 'top-right',
						})
						// REQ -  R ISO 147 Ajuste en experiencia de usuario Comparador  - se retira la pestaña SANTANDER 
						/*if (item.IdBanco == 10) {
							this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER', false)
							this.$router.go(0)
						}*/
						this.dameCaso()

					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en BorrarSolicita',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		openFavs() {
			this.RegistraLog()
			this.$router.push('/imprimirFav/' + this.id).catch(() => {})
		},
		generaSolicitud(item) {
			let self = this
			var objRequestSolicitaCaso = {
				strApiKey: this.key,
				strMetodo: 'GeneraSolicitud',
				objSolicitud: {
					Id: this.id,
					Banco: item.BancoId,
					Variante: item.Id,
				},
			}
			this.$axios
				.post('/', objRequestSolicitaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.urlSolicitud = response.data.objContenido.Solicitud
						this.popupSolicitud = true
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en GeneraSolicitud',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		solicitar(item) {
			let self = this
			var objRequestSolicitaCaso = {
				strApiKey: this.key,
				strMetodo: 'Solicita',
				intVariante: item.Id,
				objCaso: {
					Id: this.id,
				},
			}
			
			this.$axios
				.post('/', objRequestSolicitaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameCaso()
						// REQ -  R ISO 147 Ajuste en experiencia de usuario Comparador  - se retira la pestaña SANTANDER 
						/*if (item.BancoId == 10) {
							this.$emit('existeSolicitadoSantander', true)
							this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER', true)
						}*/

						this.$vs.notify({
							title: `Agregado exitosamente ${item.Id}`,
							text: `${item.Producto}`,
							position: 'top-right',
						})
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en Solicita',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		updateCaso() {		
			
			//Ministraciones

				let porcentajes = ''

				if (this.usoMinistraciones == 'Si') {
					porcentajes = this.camposID.name[1]

					for (let p = 2; p <= this.campos; p++) {													
						porcentajes = porcentajes + ',' + this.camposID.name[p]
					}
					porcentajes = porcentajes + ','						
				}else{
					this.plazoContruccion = ''
					this.periodo = ''	
					this.campos = ''				
				}
			
			// quitar comas numeros
			let aportaVol = '';
			let numAporte = '';
			if (this.hasAportacion == "Si") {
				// recalculo para casos especiales
				let plazo = Math.max(...this.caso.plazos);
				let periodo = this.tipoPlazo;
				let limiteAportacion = 0;
				
				// calcula el limite
				if (plazo > 0 && periodo > 0) {
					switch (periodo) {
						case 1:
							// mensual
							limiteAportacion = 12 * plazo;
							break;
						case 2:
							// bimestral
							limiteAportacion = (12 / 2) * plazo;
							break;
						case 3:
							// semestral
							limiteAportacion = (12 / 6) * plazo;
							break;
						case 4:
							// anual
							limiteAportacion = plazo;
							break;
					}
				}
				
				// limita el valor
				if ( this.numeroAportacion > limiteAportacion) {
					this.numeroAportacion = '';
					return; // No guardar ni cotizar, por valores incorrectos
				}
				
				aportaVol = this.aportacionVoluntaria;
				numAporte = this.numeroAportacion;
				
				aportaVol = parseFloat(aportaVol.replaceAll(',', ''));
				if (typeof(numAporte) == "string") {
					numAporte = parseInt(numAporte.replaceAll(',', ''));
				}
				// numAporte = parseInt(numAporte.replaceAll(',', ''));
			}

			let self = this
			//alert('Credito UpdateCaso'+ this.caso.valorInmueble)			
			
			// this.$vs.loading({
			// 	container: '#datosCotizacion',
			// 	scale: 0.6,
			// })
			if (this.caso.montoCredito !== 0 && this.caso.PrecalificacionTipo != 3) { // condiciono porque genera errores aleatorios
				//no usar replace cuando es 0
				var auxMontoCredito = parseFloat(this.caso.montoCredito.replace(/,/g, ''))
				this.caso.montoCredito = auxMontoCredito
				//parseFloat(this.caso.montoCredito.replace(/,/g,""))
			}
			let objRequestRegistraCaso1 = {}
			let esquemas = []
			for (var i = 0; i < this.caso.esquemas.length; i++) {
				esquemas.push(parseInt(this.caso.esquemas[i].value))
			}			
			let bancos = []
			for (var i = 0; i < this.caso.bancos.length; i++) {
				bancos.push(this.caso.bancos[i].value.toString())
			}

		    if ( (this.destino == 19 || this.destino == 25 || this.destino == 22 || this.destino == 33 || this.destino == 34) && ( this.caso.programa == 6 || this.caso.programa == 1)) {
				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						Id: parseInt(this.id),
						ValorInmueble: this.caso.valorInmueble.replace(/,/g, ''),
						SaldoInsoluto: this.caso.montoSolicitado.replace(/,/g, ''),
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						Programas: [this.caso.programa],
						Estatus: this.caso.estatus,
						Esquemas: esquemas,
						PlazoRestante: this.caso.plazoRestante,
						PagoMensualActual: this.caso.pagoMensualActual.replace(/,/g, ''),
						Bancos: bancos,
						Plaza: this.caso.plaza,
						PerfilCliente: this.caso.tasa,
						GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
					objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						prepagoAportacion: this.tipoPrepago,
						plazoAportacion: this.tipoPlazo,
						montoAportacion: aportaVol,
						cantidadAportaciones: numAporte,
					},
				}
			}else if ( (this.destino == 19 || this.destino == 25 || this.destino == 22 || this.destino == 33 || this.destino == 34) && ( this.caso.programa == 4 || this.caso.programa == 9)) {
				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						Id: parseInt(this.id),
						ValorInmueble: this.caso.valorInmueble.replace(/,/g, ''),
						SaldoInsoluto: this.caso.montoSolicitado.replace(/,/g, ''),
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						Programas: [this.caso.programa],
						Estatus: this.caso.estatus,
						Esquemas: esquemas,
						PlazoRestante: this.caso.plazoRestante,
						PagoMensualActual: this.caso.pagoMensualActual.replace(/,/g, ''),
						
						CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),            
						SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),

						Bancos: bancos,
						Plaza: this.caso.plaza,
						PerfilCliente: this.caso.tasa,
						GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
					objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						prepagoAportacion: this.tipoPrepago,
						plazoAportacion: this.tipoPlazo,
						montoAportacion: aportaVol,
						cantidadAportaciones: numAporte,
					},
				}
			} else if ((this.destino == 1 || this.destino == 5 || this.destino == 2) && (this.caso.programa == 6 || this.caso.programa == 1)) {
				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						Id: parseInt(this.id),
						ValorInmueble: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoSolicitado: parseFloat(this.caso.montoSolicitado.replace(/,/g, '')),
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						Estatus: this.caso.estatus,
						Esquemas: esquemas,
						Bancos: bancos,
						Plaza: this.caso.plaza,
						Programas: [this.caso.programa],
						PerfilCliente: this.caso.tasa,
						GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
					objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						prepagoAportacion: this.tipoPrepago,
						plazoAportacion: this.tipoPlazo,
						montoAportacion: aportaVol,
						cantidadAportaciones: numAporte,
					},
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			} else if (this.destino == 20 && this.caso.programa == 6) {
				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						Id: parseInt(this.id),
						ValorInmueble: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoSolicitado: 0,
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						CreditoInfonavit: parseFloat(this.caso.creditoInf.replace(/,/g, '')),
						SubCuentaVivienda: parseFloat(this.caso.ssv.replace(/,/g, '')),
						Estatus: this.caso.estatus,
						Esquemas: esquemas,
						Bancos: bancos,
						Plaza: this.caso.plaza,
						Programas: [this.caso.programa],
						PerfilCliente: this.caso.tasa,
						GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
					objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						prepagoAportacion: this.tipoPrepago,
						plazoAportacion: this.tipoPlazo,
						montoAportacion: aportaVol,
						cantidadAportaciones: numAporte,
					},
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			} else if ((this.destino == 1 || this.destino == 5 || this.destino == 2 || this.destino == 20) && (this.caso.programa == 4 || this.caso.programa == 9)) {
				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						Id: parseInt(this.id),
						ValorInmueble: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoSolicitado: 0,
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						CreditoInfonavit: parseFloat(this.caso.creditoInf.replace(/,/g, '')),
						SubCuentaVivienda: parseFloat(this.caso.ssv.replace(/,/g, '')),
						Estatus: this.caso.estatus,
						Esquemas: esquemas,
						Bancos: bancos,
						Plaza: this.caso.plaza,
						Programas: [this.caso.programa],
						PerfilCliente: this.caso.tasa,
						MontoCredito: this.caso.montoCredito,
						GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
					objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						prepagoAportacion: this.tipoPrepago,
						plazoAportacion: this.tipoPlazo,
						montoAportacion: aportaVol,
						cantidadAportaciones: numAporte,
					},
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			} else if (this.destino == 20) {
				//alert(parseFloat(this.caso.valorTerreno))
				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						Id: parseInt(this.id),
						ValorInmueble: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoSolicitado: parseFloat(this.caso.montoSolicitado.replace(/,/g, '')),
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						Estatus: this.caso.estatus,
						Esquemas: esquemas,
						Bancos: bancos,
						Plaza: this.caso.plaza,
						Programas: [this.caso.programa],
						PerfilCliente: this.caso.tasa,
						GastoNotarial: this.caso.gastosNotarial,
						ValorTerreno: parseFloat(this.caso.valorTerreno),
						ValorProyecto: parseFloat(this.caso.valorProyecto),
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
					objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						prepagoAportacion: this.tipoPrepago,
						plazoAportacion: this.tipoPlazo,
						montoAportacion: aportaVol,
						cantidadAportaciones: numAporte,
					},
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			} else if(this.autosDestinos.includes(this.destino)) { // Automotriz
			
				// formato de plazos
				let plazos = this.caso.plazos;

				// console.log( 'antesGuardar', plazos );
				// 6,12,24,36,48
				// 0,1,2,3,4
				// return;

				this.caso.plazos = []
				plazos.forEach(plazo => {
					if (typeof plazo == 'object') {
						this.caso.plazos.push(parseInt(plazo.value));
					} else if (typeof plazo == 'string') {
						this.caso.plazos.push(parseInt(plazo))
					} else {
						let ps = 6;
						if (plazo > 0) {
							ps = plazo * 12;
						}
						this.caso.plazos.push(ps)
					}
				});

				// console.log( 'despuesGuardar', this.caso.plazos );
				// return;

				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCasoAuto',
					objCaso: {
						Id: parseInt(this.id),
						PrecalificacionTipo: this.caso.PrecalificacionTipo,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						ValorVehiculo: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoEnganche: parseFloat(this.caso.montoSolicitado.replace(/,/g, '')),
						// Anio: 2020, // temp
						Anio: parseInt(this.caso.aniosACotizar),
						// Plazos: ["120","108","96","84"], // temp
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Esquemas: [2], // fijo
						Plaza: this.caso.plaza,
						Bancos: bancos,
						VersionOrigen: this.caso.VersionOrigen
					}
				}

				// console.log( 'Automotriz', objRequestRegistraCaso1 );


			} else {
				objRequestRegistraCaso1 = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						Id: parseInt(this.id),
						ValorInmueble: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoSolicitado: parseFloat(this.caso.montoSolicitado.replace(/,/g, '')),
						Plazos: this.caso.plazos,
						Solicitante: this.caso.email,
						Broker: this.caso.ejecutivo,
						Destino: this.destino,
						Estatus: this.caso.estatus,
						Esquemas: esquemas,
						Bancos: bancos,
						Plaza: this.caso.plaza,
						Programas: [this.caso.programa],
						PerfilCliente: this.caso.tasa,
						GastoNotarial: this.caso.gastosNotarial,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
					objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						prepagoAportacion: this.tipoPrepago,
						plazoAportacion: this.tipoPlazo,
						montoAportacion: aportaVol,
						cantidadAportaciones: numAporte,
					},
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			}
			this.$axios
				.post('/', objRequestRegistraCaso1, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						//this.logEsNuevo = response.data.objContenido.Nuevo
						//alert(this.caso.gastosNotarial+'UpdateCaso')
						// BUG
						this.registraAdicionales()
						this.cotiza(response.data.objContenido.Id) ///response.data.objContenido.Id, response.data.objContenido.Destino  anali
						this.dameCaso(true)
						this.dameLogCotiza()
			
						let tabs = document.querySelectorAll(".nav-tabs button");
						if (this.bandera) {
							tabs[1].click()	
						}											
						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCaso',
							text: response.data.strError,
							color: 'danger', 
							position: 'top-right',
						})
						this.dameCaso(true)
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 1',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

		update(e) {
			this.porcentajeActivaCotiza = true; // si deja inactivo auto cambiando a hipotecario

			if (e.otro) {
				this.otro = e.otro
			}

			//alert('Credito update'+ e.valor)
			// this.destino= e.destino
			this.caso.plazos = []
			this.caso.esquemas = []
			//this.caso.gastosNotarial=e.gastosNotarial
			for (var i = 0; i < e.plazosACotizar.length; i++) {
				if (typeof e.plazosACotizar[i] == 'object') {
					this.caso.plazos.push(e.plazosACotizar[i].value)
				} else {
					this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
				}
			}
			for (var i = 0; i < e.esquemasACotizar.length; i++) {
				this.caso.esquemas.push(e.esquemasACotizar[i])
			}

			// console.log(this.caso.esquemas)
		
			 if (e.destino == 19 || e.destino == 25 || e.destino == 22 || e.destino == 33 || e.destino == 34) {
				// this.destino=e.destino
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = e.credito
				this.caso.pagoMensualActual = e.mensualidad
				this.caso.plazoRestante = e.plazoRestante
			} else if (e.destino == 11) {
				this.caso.valorInmueble = e.valor
				this.caso.ssv = e.ssv
				this.caso.creditoInf = e.creditoInf

				// TODO: Es regla implica que en un destino Aquisicion Confinavit el monto solicitando estara en 0, se retira a peticion de ticket #76864
				this.caso.montoSolicitado = e.montoCredito;
				// this.caso.montoSolicitado = '0'

				if (this.caso.programa == 9 || this.caso.programa == 4) {
					this.caso.montoCredito = e.montoCredito
				}
			} else if (e.destino == 20) {
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = e.credito
				this.caso.valorTerreno = e.valorTerreno
				this.caso.valorProyecto = e.proyecto
			} else if (this.autosDestinos.includes(e.destino)) {
				this.caso.aniosACotizar = e.aniosACotizar;
				this.caso.valorInmueble = e.valor;
				this.caso.montoSolicitado = e.credito;
				this.caso.plazos = e.plazosACotizar;
				this.caso.destino = e.destino;

				if (e.activaCotizaMenor || e.activaCotizaMayor) { // si uno de los 2 es invalido
					this.porcentajeActivaCotiza = false;
				} else {
					this.porcentajeActivaCotiza = true;
				}

			} else {
				//this.destino=e.destino
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = e.credito
			}
			//alert(this.destino)

			// console.log( 'update Credito.vue', e );
			// console.log( 'update CreditoVue.vue', this.caso );
		},
		filtrar() {
			var tipo = this.filtroModel

			switch (tipo) {
				case '1':
					this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0)) //Cambio Mensualidad por MensualidadInicial
					this.filtroName = 'Mensualidad Inicial'
					this.filtroId = 1
					break
				case '2':
					this.items.sort((a, b) => (a.Cat > b.Cat ? 1 : b.Cat > a.Cat ? -1 : 0))
					this.filtroName = 'Cat'
					this.filtroId = 2
					break
				case '3':
					this.items.sort((a, b) => (a.Tasa > b.Tasa ? 1 : b.Tasa > a.Tasa ? -1 : 0))
					this.filtroName = 'Tasa'
					this.filtroId = 3
					break
				case '4':
					this.items.sort((a, b) => (a.PagoTotal > b.PagoTotal ? 1 : b.PagoTotal > a.PagoTotal ? -1 : 0))
					this.filtroName = 'Pago Total'
					this.filtroId = 4
					break
				case '5':
					this.items.sort((a, b) => (a.vctPromociones.length < b.vctPromociones.length ? 1 : b.vctPromociones.length < a.vctPromociones.length ? -1 : 0))
					this.filtroName = 'Promociones'
					this.filtroId = 5
					break
				case '6':
					this.items.sort((a, b) => (a.IngresoMinimo > b.IngresoMinimo ? 1 : b.IngresoMinimo > a.IngresoMinimo ? -1 : 0))
					this.filtroName = 'Ingreso Mínimo'
					this.filtroId = 6
					break
				case '7':
					this.items.sort((a, b) => (a.AhorroTotal < b.AhorroTotal ? 1 : b.AhorroTotal < a.AhorroTotal ? -1 : 0))
					this.filtroName = 'Ahorro Total'
					this.filtroId = 7
					break
				case '8':
					this.items.sort((a, b) => (a.MejoraMensualidad < b.MejoraMensualidad ? 1 : b.MejoraMensualidad < a.MejoraMensualidad ? -1 : 0))
					this.filtroName = 'Mejor Mensualidad'
					this.filtroId = 8
					break
				case '9':
					this.items.sort((a, b) => (a.Banco > b.Banco ? 1 : b.Banco > a.Banco ? -1 : 0))
					this.filtroName = 'Alfabético'
					this.filtroId = 9
					break
			}
		},
		comprobarFavorito(item) {
			if (item.BancoId == 21 && this.caso.favoritos) {
				for (var i = 0; i < this.caso.favoritos.length; i++) {
					if (this.caso.favoritos[i].BancoId == 21 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa) {
						return false
					}
				}
			}else if (item.BancoId == 33 && this.caso.favoritos) {
				for (var i = 0; i < this.caso.favoritos.length; i++) {
					if (this.caso.favoritos[i].BancoId == 33 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa && this.caso.favoritos[i].Plazo == item.Plazo ) {
						return false
					}
				}
			} else {
				if (this.caso.favoritos) {
					for (var i = 0; i < this.caso.favoritos.length; i++) {
						if (this.caso.favoritos[i].Id == item.Id) {
							return false
						}
					}
				}
			}

			return true
		},
		quitarFavorito(item) {
			let self = this
			let objRequestFavoritoCaso = {
				strApiKey: this.key,
				strMetodo: 'NoFavorito',
				intVariante: item.Id,
				objCaso: {
					Id: this.id,
					IdBanco: item.BancoId,
					IdIon: item.IdIon,
				},
			}
			this.$axios
				.post('/', objRequestFavoritoCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameCaso()
						this.$vs.notify({
							title: `Eliminado exitosamente ${item.Id}`,
							text: `${item.Producto}`,
							position: 'top-right',
						})
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en NoFavorito',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 2',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		agregarFavorito(item) {

			const countFavoritos = this.caso.favoritos.length
			if ( countFavoritos >= 5 ) return

			let self = this
			let objRequestFavoritoCaso = {
				strApiKey: this.key,
				strMetodo: 'Favorito',
				intVariante: item.Id,
				objCaso: {
					Id: this.id,

					IdBanco: item.BancoId,
					IdIon: item.IdIon,
					intCat: item.Cat,
					ValorInmueble: this.caso.valorInmueble.replace(/,/g, ''),
					MontoSolicitado: this.caso.montoSolicitado.replace(/,/g, ''),
					intMensualidad: item.Mensualidad,
					intTasa: item.Tasa,
					intIngresoMinimo: item.IngresoMinimo,
					intEnganche: item.Enganche,
					intGastosIniciales: item.GastosIniciales,
					intAforo: item.Aforo,
					intPlazo: item.Plazo,
					intPagoTotal: item.PagoTotal,
					intMensualidadPromedio: item.MensualidadPromedio,
					intMensualidadInicial: item.MensualidadInicial,
					intEsquemas: item.Esquemas,
				},
			}
			this.$axios
				.post('/', objRequestFavoritoCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameCaso()
						this.$vs.notify({
							title: 'Agregado exitosamente',
							text: `${item.Producto}`,
							position: 'top-right',
						})
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en NoFavorito',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 3',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

		async getListProductsMensualidad ({ listProducts }) {

			try {
	
				const objRequestListProducts = {
					strApiKey: this.key,
					strMetodo: 'ListProductsMensualidad',
					objList: {
						listProducts
					}
				}

				const resp = await this.$axios.post('/', objRequestListProducts, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				const { intEstatus, data } = resp
				const { objContenido } = data

				if ( !objContenido ) {
					this.$vs.notify({
						title: 'Ocurrio un error en ListProductsMensualidad',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return []
				}

				return objContenido
				
			} catch (error) {
				this.$vs.notify({
					title: 'Ocurrio un error de sistema 4',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			}

		},

		async cotiza() {

			try {
				this.$vs.loading({
					container: '#cotizacion',
					scale: 0.6,
				})

				this.items = []
				let objRequestCotizaCaso = {
					strApiKey: this.key,
					strMetodo: 'Cotiza',
					objCaso: {
						Id: this.id,
						//GastoNotarial: this.caso.gastosNotarial
					},
				}

				const resp = await this.$axios.post('/', objRequestCotizaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				const { intEstatus, data } = resp

				if ( intEstatus ) {
					this.$vs.notify({
						title: 'Ocurrio un error en Cotiza',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}

				let bancos = []
				let esquemas = []

				for (var i = 0; i < this.caso.bancos.length; i++) {
					if (this.caso.bancos[i].value) {
						bancos.push(parseInt(this.caso.bancos[i].value))
					} else {
						bancos.push(parseInt(this.caso.bancos[i]))
					}
				}

				for (var i = 0; i < this.caso.esquemas.length; i++) {
					if (this.caso.esquemas[i].value) {
						esquemas.push(parseInt(this.caso.esquemas[i].value))
						// console.log(this.caso.esquemas)
						// console.log(this.caso.esquemas[i].value)
					} else {
						esquemas.push(parseInt(this.caso.esquemas[i]))
						// console.log(this.caso.esquemas)
						// console.log(this.caso.esquemas[i])
					}
				}

				this.caso.plazos = []
				for (var j = 0; j < this.PlazosKrediInicio.length; j++) {
					this.caso.plazos.push(this.PlazosKrediInicio[j])
				}

				// Consultar segunda mensualidad para todos los productos
				const listProducts = data.objContenido ? data.objContenido : []
				const listItems = listProducts.length > 0 ? await this.getListProductsMensualidad({ listProducts }) : listProducts
				this.items = listItems

				if (this.caso.programa == 1 && esquemas.includes(2) && (this.destino == 1 || this.destino == 5 || this.destino == 3 || this.destino == 37) && bancos.includes(21)) {
					// alert("if 1")
					for (var j = 0; j < this.caso.plazos.length; j++) {
						// no entra factor santander porcentual por plazos
						if (this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(21) && (this.destino == 5 || this.destino == 37) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 6 || this.caso.plazos[j] == 7 || this.caso.plazos[j] == 8 || this.caso.plazos[j] == 9 || this.caso.plazos[j] == 10)) {
							// alert("if 2")
							this.pruebaIon(this.caso.plazos[j], this.destino)
							// this.items = response.data.objContenido

							for (var i = 0; i < this.items.length; i++) {
								this.items[i].GastosNotariales = (this.caso.gastosNotarial / 100) * this.ValorViendaCofi
								this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
							}

							this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0)) //Cambio Mensualidad por MensualidadInicial
							this.filtroName = 'Mensualidad Inicial'
							this.filtroId = 1

							this.$vs.loading({
								container: '#cotizacion',
								scale: 0.6,
							})
						}

						// no entra factor santander porcentual por plazos
						if (this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(21) && (this.destino == 1 || this.destino == 3) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 6 || this.caso.plazos[j] == 7 || this.caso.plazos[j] == 8 || this.caso.plazos[j] == 9 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 11 || this.caso.plazos[j] == 12 || this.caso.plazos[j] == 13 || this.caso.plazos[j] == 14 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 16 || this.caso.plazos[j] == 17 || this.caso.plazos[j] == 18 || this.caso.plazos[j] == 19 || this.caso.plazos[j] == 20)) {
							// alert("if 3")
							// console.log(this.caso.programa + '&&' + esquemas.includes(2) + ' &&' + this.destino + '&&' + bancos.includes(21))

							this.pruebaIon(this.caso.plazos[j], this.destino)
							// this.items = response.data.objContenido

							for (var i = 0; i < this.items.length; i++) {
								this.items[i].GastosNotariales = (this.caso.gastosNotarial / 100) * this.ValorViendaCofi
								this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
							}

							this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0)) //Cambio Mensualidad por MensualidadInicial
							this.filtroName = 'Mensualidad Inicial'
							this.filtroId = 1

							this.$vs.loading({
								container: '#cotizacion',
								scale: 0.6,
							})
						} else {
							// this.items = response.data.objContenido

							for (var i = 0; i < this.items.length; i++) {
								if (
									this.items[i].Variante == 4 ||
									this.items[i].Variante == 6 ||
									this.items[i].Variante == 14 ||
									this.items[i].Variante == 21 ||
									this.items[i].Variante == 22 ||
									this.items[i].Variante == 26 ||
									this.items[i].Variante == 30 ||
									this.items[i].Variante == 31 ||
									this.items[i].Variante == 35 ||
									this.items[i].Variante == 37 ||
									this.items[i].Variante == 40 ||
									this.items[i].Variante == 51 ||
									this.items[i].Variante == 65 ||
									this.items[i].Variante == 133 ||
									this.items[i].Variante == 138 ||
									this.items[i].Variante == 171 ||
									this.items[i].Variante == 176 ||
									this.items[i].Variante == 184 ||
									this.items[i].Variante == 194 ||
									this.items[i].Variante == 197 ||
									this.items[i].Variante == 198 ||
									this.items[i].Variante == 9 ||
									this.items[i].Variante == 71 ||
									this.items[i].Variante == 72 ||
									this.items[i].Variante == 102 ||
									this.items[i].Variante == 103 ||
									this.items[i].Variante == 154 ||
									this.items[i].Variante == 75 ||
									this.items[i].Variante == 129 ||
									this.items[i].Variante == 131 ||
									this.items[i].Variante == 139 ||
									this.items[i].Variante == 175 ||
									this.items[i].Variante == 11 ||
									this.items[i].Variante == 17 ||
									this.items[i].Variante == 23 ||
									this.items[i].Variante == 24 ||
									this.items[i].Variante == 39 ||
									this.items[i].Variante == 96 ||
									this.items[i].Variante == 109 ||
									this.items[i].Variante == 144 ||
									this.items[i].Variante == 182 ||
									this.items[i].Variante == 183 ||
									this.items[i].Variante == 189 ||
									this.items[i].Variante == 190 ||
									this.items[i].Variante == 193 ||
									this.items[i].Variante == 10 ||
									this.items[i].Variante == 66 ||
									this.items[i].Variante == 67 ||
									this.items[i].Variante == 85 ||
									this.items[i].Variante == 97 ||
									this.items[i].Variante == 98 ||
									this.items[i].Variante == 168 ||
									this.items[i].Variante == 82 ||
									this.items[i].Variante == 132 ||
									this.items[i].Variante == 7 ||
									this.items[i].Variante == 69 ||
									this.items[i].Variante == 73 ||
									this.items[i].Variante == 81 ||
									this.items[i].Variante == 84 ||
									this.items[i].Variante == 104 ||
									this.items[i].Variante == 105 ||
									this.items[i].Variante == 166 ||
									this.items[i].Variante == 32 ||
									this.items[i].Variante == 202 ||
									this.items[i].Variante == 203 ||
									this.items[i].Variante == 204 ||
									this.items[i].Variante == 205 ||
									this.items[i].Variante == 206 ||
									this.items[i].Variante == 208 ||
									this.items[i].Variante == 211 ||
									this.items[i].Variante == 212
								) {
									this.items[i].Enganche = 0
								}

								/////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
								if (this.items[i].Variante == 4 || this.items[i].Variante == 171) {
									// alert("if 5")
									this.items[i].GastosNotariales = 0
									this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
								} else if (this.items[i].Variante == 182 || this.items[i].Variante == 183 || this.items[i].Variante == 190 || this.items[i].Variante == 32 || this.items[i].Variante == 189 || this.items[i].Variante == 11 || this.items[i].Variante == 39 || this.items[i].Variante == 17 || this.items[i].Variante == 23 || this.items[i].Variante == 24 || this.items[i].Variante == 96 || this.items[i].Variante == 109 || this.items[i].Variante == 144 || this.items[i].Variante == 167 || this.items[i].Variante == 168 || this.items[i].Variante == 170 || this.items[i].Variante == 40 || this.items[i].Variante == 66 || this.items[i].Variante == 67 || this.items[i].Variante == 71 || this.items[i].Variante == 72 || this.items[i].Variante == 85 || this.items[i].Variante == 9 || this.items[i].Variante == 10 || this.items[i].Variante == 97 || this.items[i].Variante == 98 || this.items[i].Variante == 102 || this.items[i].Variante == 103) {
									this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
									this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
								} else if (this.items[i].Variante == 19 || this.items[i].Variante == 20 || this.items[i].Variante == 25 || this.items[i].Variante == 163) {
									////Scotiabank tiene promo de 20% de descuento
									// alert("if 7")
									this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * this.ValorViendaCofi
									this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
								}

								// TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
								// TODO: para pruebas ( 156, 157 )							

								//ID DE PRODUCTOS INTEGRALES
							else if(this.items[i].Variante == 191 || this.items[i].Variante == 192) {//PROD
										//else if (this.items[i].Variante == 174 || this.items[i].Variante == 175) {//DEV

									this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))

									this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales + this.calculaFactorSantander(this.items[i].Aforo, this.items[i].Plazo / 12, this.items[i].Monto)
								} else {
									this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * this.ValorViendaCofi
									this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales									
								}

							}

							this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0)) //Cambio Mensualidad por MensualidadInicial
							this.filtroName = 'Mensualidad Inicial'
							this.filtroId = 1
							this.$vs.loading.close('#cotizacion > .con-vs-loading')
						}
					}
				} else {
					///validamos si trae banco KREDI, cumple plazos y tipo de esquemas
					// console.log(this.PlazosKrediInicio.length)
					for (var j = 0; j < this.caso.plazos.length; j++) {
						// console.log(this.caso.plazos[j])
						if (this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(33) && this.destino == 1 && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20)) {
							//	alert('adentro'+this.caso.plazos[j])
							this.ValorInmuebleKredi = this.ValorViendaCofi
							this.EngancheKredi = this.ValorViendaCofi - parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
							this.PorcentajeEngancheKredi = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / this.ValorViendaCofi) * 100
							this.PlazosKredi = this.caso.plazos[j]
							this.FinanciamientoKredi = 'No'
							this.ComisionAperturaKredi = '0.02'
							this.EstadoKredi = this.caso.estado
							this.GastosNotarialesKredi = this.caso.gastosNotarial / 100

							this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)

							/*  se comenta por requerimiento -  Actualización en cotizaciones Kredi
							
								////ahora validamos el estado dependiendo de los gastos notariales
								if ((this.caso.estado == 16 || this.caso.estado == 20 || this.caso.estado == 21 || this.caso.estado == 31) && this.caso.gastosNotarial == 5) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if (this.caso.estado == 30 && (this.caso.gastosNotarial == 4 || this.caso.gastosNotarial == 5)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if ((this.caso.estado == 7 || this.caso.estado == 10 || this.caso.estado == 17) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 7)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if ((this.caso.estado == 22 || this.caso.estado == 9) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 8)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if ((this.caso.estado == 23 || this.caso.estado == 9) && (this.caso.gastosNotarial == 9 || this.caso.gastosNotarial == 5)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if ((this.caso.estado == 1 || this.caso.estado == 2 || this.caso.estado == 3 || this.caso.estado == 4 || this.caso.estado == 5 || this.caso.estado == 6 || this.caso.estado == 8 || this.caso.estado == 11 || this.caso.estado == 12 || this.caso.estado == 13 || this.caso.estado == 14 || this.caso.estado == 15 || this.caso.estado == 18 || this.caso.estado == 19 || this.caso.estado == 24 || this.caso.estado == 25 || this.caso.estado == 26 || this.caso.estado == 27 || this.caso.estado == 28 || this.caso.estado == 29) && (this.caso.gastosNotarial == 6 || this.caso.gastosNotarial == 5)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if (this.caso.estado == 32 && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 6)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								}
							*/

							// this.items = response.data.objContenido

							for (var i = 0; i < this.items.length; i++) {
								this.items[i].GastosNotariales = (this.caso.gastosNotarial / 100) * this.ValorViendaCofi
								this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
							}

							this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0)) //Cambio Mensualidad por MensualidadInicial
							this.filtroName = 'Mensualidad Inicial'
							this.filtroId = 1

							this.$vs.loading.close('#cotizacion > .con-vs-loading')
						} else if (this.caso.programa == 1 && esquemas.includes(2) && bancos.includes(33) && this.destino == 19 && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20 || this.caso.plazos[j] == 25 || this.caso.plazos[j] == 30)) {
							this.ValorInmuebleKredi = this.ValorViendaCofi
							this.EngancheKredi = this.ValorViendaCofi - parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
							this.PorcentajeEngancheKredi = (parseFloat(this.caso.montoSolicitado.replace(/,/g, '')) / this.ValorViendaCofi) * 100
							this.PlazosKredi = this.caso.plazos[j]
							this.FinanciamientoKredi = 'No'
							this.ComisionAperturaKredi = '0.02'
							this.EstadoKredi = this.caso.estado
							this.GastosNotarialesKredi = this.caso.gastosNotarial / 100

						/*	this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)

							  se comenta por requerimiento -  Actualización en cotizaciones Kredi
							
								////ahora validamos el estado dependiendo de los gastos notariales
								if ((this.caso.estado == 16 || this.caso.estado == 20 || this.caso.estado == 21 || this.caso.estado == 17 || this.caso.estado == 1 || this.caso.estado == 2 || this.caso.estado == 3 || this.caso.estado == 4 || this.caso.estado == 5 || this.caso.estado == 6 || this.caso.estado == 8 || this.caso.estado == 12 || this.caso.estado == 13 || this.caso.estado == 14 || this.caso.estado == 15 || this.caso.estado == 18 || this.caso.estado == 19 || this.caso.estado == 24 || this.caso.estado == 25 || this.caso.estado == 26 || this.caso.estado == 27 || this.caso.estado == 28 || this.caso.estado == 29 || this.caso.estado == 32) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 3)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if ((this.caso.estado == 31 || this.caso.estado == 30) && (this.caso.gastosNotarial == 2 || this.caso.gastosNotarial == 5)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								} else if ((this.caso.estado == 7 || this.caso.estado == 10 || this.caso.estado == 9 || this.caso.estado == 23 || this.caso.estado == 22 || this.caso.estado == 11) && (this.caso.gastosNotarial == 5 || this.caso.gastosNotarial == 4)) {
									this.RegistraCasoKredi(this.destino, this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
								}
							*/

							// this.items = response.data.objContenido

							for (var i = 0; i < this.items.length; i++) {
								this.items[i].GastosNotariales = (this.caso.gastosNotarial / 100) * this.ValorViendaCofi
								this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
							}

							this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0)) //Cambio Mensualidad por MensualidadInicial
							this.filtroName = 'Mensualidad Inicial'
							this.filtroId = 1

							this.$vs.loading.close('#cotizacion > .con-vs-loading')
						}
					}

					// this.items = response.data.objContenido
					//alert(this.items[i].valorInmueble)
					for (var i = 0; i < this.items.length; i++) {
						if (
							this.items[i].Variante == 4 ||
							this.items[i].Variante == 6 ||
							this.items[i].Variante == 14 ||
							this.items[i].Variante == 21 ||
							this.items[i].Variante == 22 ||
							this.items[i].Variante == 26 ||
							this.items[i].Variante == 30 ||
							this.items[i].Variante == 31 ||
							this.items[i].Variante == 35 ||
							this.items[i].Variante == 37 ||
							this.items[i].Variante == 40 ||
							this.items[i].Variante == 51 ||
							this.items[i].Variante == 65 ||
							this.items[i].Variante == 133 ||
							this.items[i].Variante == 138 ||
							this.items[i].Variante == 171 ||
							this.items[i].Variante == 176 ||
							this.items[i].Variante == 184 ||
							this.items[i].Variante == 194 ||
							this.items[i].Variante == 197 ||
							this.items[i].Variante == 198 ||
							this.items[i].Variante == 9 ||
							this.items[i].Variante == 71 ||
							this.items[i].Variante == 72 ||
							this.items[i].Variante == 102 ||
							this.items[i].Variante == 103 ||
							this.items[i].Variante == 154 ||
							this.items[i].Variante == 75 ||
							this.items[i].Variante == 129 ||
							this.items[i].Variante == 131 ||
							this.items[i].Variante == 139 ||
							this.items[i].Variante == 175 ||
							this.items[i].Variante == 11 ||
							this.items[i].Variante == 17 ||
							this.items[i].Variante == 23 ||
							this.items[i].Variante == 24 ||
							this.items[i].Variante == 39 ||
							this.items[i].Variante == 96 ||
							this.items[i].Variante == 109 ||
							this.items[i].Variante == 144 ||
							this.items[i].Variante == 182 ||
							this.items[i].Variante == 183 ||
							this.items[i].Variante == 189 ||
							this.items[i].Variante == 190 ||
							this.items[i].Variante == 193 ||
							this.items[i].Variante == 10 ||
							this.items[i].Variante == 66 ||
							this.items[i].Variante == 67 ||
							this.items[i].Variante == 85 ||
							this.items[i].Variante == 97 ||
							this.items[i].Variante == 98 ||
							this.items[i].Variante == 168 ||
							this.items[i].Variante == 82 ||
							this.items[i].Variante == 132 ||
							this.items[i].Variante == 7 ||
							this.items[i].Variante == 69 ||
							this.items[i].Variante == 73 ||
							this.items[i].Variante == 81 ||
							this.items[i].Variante == 84 ||
							this.items[i].Variante == 104 ||
							this.items[i].Variante == 105 ||
							this.items[i].Variante == 166 ||
							this.items[i].Variante == 32 ||
							this.items[i].Variante == 202 ||
							this.items[i].Variante == 203 ||
							this.items[i].Variante == 204 ||
							this.items[i].Variante == 205 ||
							this.items[i].Variante == 206 ||
							this.items[i].Variante == 208 ||
							this.items[i].Variante == 211 ||
							this.items[i].Variante == 212
						) {
							this.items[i].Enganche = 0
						}

						/////validara si son los productos 4 y 171 ya q tienen promocion de gastos en 0
						if (this.items[i].Variante == 4 || this.items[i].Variante == 171) {
							// alert("if 12")
							this.items[i].GastosNotariales = 0
							this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
						} else if (this.items[i].Variante == 182 || this.items[i].Variante == 183 || this.items[i].Variante == 190 || this.items[i].Variante == 32 || this.items[i].Variante == 189 || this.items[i].Variante == 11 || this.items[i].Variante == 39 || this.items[i].Variante == 17 || this.items[i].Variante == 23 || this.items[i].Variante == 24 || this.items[i].Variante == 96 || this.items[i].Variante == 109 || this.items[i].Variante == 144 || this.items[i].Variante == 167 || this.items[i].Variante == 168 || this.items[i].Variante == 170 || this.items[i].Variante == 66 || this.items[i].Variante == 67 || this.items[i].Variante == 71 || this.items[i].Variante == 72 || this.items[i].Variante == 85 || this.items[i].Variante == 9 || this.items[i].Variante == 10 || this.items[i].Variante == 97 || this.items[i].Variante == 98 || this.items[i].Variante == 102 || this.items[i].Variante == 103) {
							// console.log(this.caso.gastosNotarial)
							this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
							// console.log(this.items[i].GastosNotariales)
							this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
						} else if (this.items[i].Variante == 19 || this.items[i].Variante == 20 || this.items[i].Variante == 25 || this.items[i].Variante == 163) {
							////Scotiabank tiene promo de 20% de descuento

							this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * this.ValorViendaCofi
							this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales

							// TODO: factor porcentual para santander (variantes solo existen en prod 191, 192)
							// TODO: para pruebas ( 156, 157 )							
						} 

						//ID DE PRODUCTOS INTEGRALES DEV

						else if(this.items[i].Variante == 191 || this.items[i].Variante == 192) {//PROD
							//else if (this.items[i].Variante == 174 || this.items[i].Variante == 175) {//DEV

							this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g, ''))

							this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales + this.calculaFactorSantander(this.items[i].Aforo, this.items[i].Plazo / 12, this.items[i].Monto)
						} else {
							this.items[i].GastosNotariales = ((this.caso.gastosNotarial * this.items[i].FactorDescuentoGastosNotariales) / 100) * this.ValorViendaCofi
							this.items[i].GastosIniciales = this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
						}
					}

			
					if (this.programaCofi == 4) {
						for (var i = 0; i < this.items.length; i++) {
							this.items[i].GastosInicialesCofinavit = this.ValorViendaCofi - this.montoCreditoCofi + this.items[i].GastosIniciales - (this.ssvCofi + this.saldoInfo)
						}
					}

					for (var i = 0; i < this.items.length; i++) {
						this.getTotalProducto(this.items[i])
					}

					this.items.sort((a, b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio ? 1 : b.MensualidadInicialPromedio > a.MensualidadInicialPromedio ? -1 : 0)) //Cambio Mensualidad por MensualidadInicial
					this.filtroName = 'Mensualidad Inicial'
					this.filtroId = 1
					this.bandera = true
					this.$vs.notify({
						title: `Cotizado y guardado exitosamente`,
						position: 'top-right',
					})
					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				}

				this.updateSelected()
				
				// console.log('caso.solicitudes :>> ', this.caso.solicitudes)
				// console.log('items :>> ', this.items)

			} catch (error) {
				this.$vs.notify({
					title: 'Ocurrio un error de sistema 5',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			}
		},

		getTotalProducto(item) {      

			let self = this
			if (this.id > 0) {
				this.idCaso = this.id
			}
			let TablaPagosIon = ''
			let AmortizacionPdf = ''
			let AmortizacionJson = ''
			if (item.BancoId == 21) {
				TablaPagosIon = true
				AmortizacionPdf = item.PdfAmortizacion
				AmortizacionJson = item.JsonAmortizacion
			}
			let objRequestDameTablaPagos = {
				strApiKey: this.key,
				strMetodo: 'DameTablaPagos',
				objResultado: {
					Id: item.Id,
					caso: this.id,
					caso: this.idCaso,
					IonAmortizacion: TablaPagosIon,
					pdfIon: AmortizacionPdf,
					jsonIon: AmortizacionJson,
					plazo: item.Plazo,
					tasa: item.Tasa,
				},
			}

			this.$axios
				.post('/', objRequestDameTablaPagos, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
					},
				})
				.then((response) => {
					if (response.data.intEstatus != 0) {
						return this.$vs.notify({
							title: 'Ocurrio un error en DameTablaPagos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}

					let pagoMensualTotal = 0
					this.itemsTable = response.data.objContenido

					for (var i = 0; i < this.itemsTable.length; i++) {            
						pagoMensualTotal = pagoMensualTotal + this.itemsTable[i].PagoMensual
					}
			item.PagoTotal = pagoMensualTotal        
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},


		updateSelected () {
			// if ( this.caso.solicitudes.length == 0 ) return

			const vctSolicitudes = this.caso.solicitudes.map(x => x.VarianteId)
			for (const item of this.items) {
				const variante = item.Variante

				item.Selected = vctSolicitudes.includes(variante) ? true : false
			}

			this.items = this.items
		},

		validateFavoritos () {

			const countFavoritos = this.caso.favoritos.length
			this.isLimitFavoritos = countFavoritos >= 5 ? true : false

		},
	
		async getDataDestino () {
			const destino = this.destino

			const objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'DameDatosDestino',
				objDestino: {
				id: destino
				}
			}

			const resp = await this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			const { intEstatus, data } = resp

			if ( intEstatus ) {
				this.$vs.notify({
				title: 'Ocurrio un error en DameDatosDestino',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})
			}

			const { objContenido } = data

			this.PorcentajeMaximo = 97 /////objContenido ? objContenido.Aforo : 98

		},

		dameCaso(flag) {
			//this.destino=1 //restaurar para que tome los valores devueltos
			this.caso.programa = 1 //restaurar para que tome los valores devueltos
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}

			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {

            const coacreditado = response.data.objContenido.Coacreditado[0]
						const emailCoacreditado = coacreditado ? coacreditado.EMail : ''
						this.isActiveCoacreditado = emailCoacreditado ? true : false

						if (response.data.objContenido.CoPropietario != null) {
							this.CoAcreditado = true	
						}
						else
						this.CoAcreditado = false

						let cotizacion = response.data.objContenido
						//console.log(cotizacion)
						this.caso.plazos = []
						this.caso.esquemas = []
						this.caso.bancos = []
						this.caso.estatus = cotizacion.Estatus
						this.caso.solicitante = cotizacion.Solicitante
						this.destino = cotizacion.Destino
						this.esquemasCaso = cotizacion.Esquemas
						
						this.caso.ejecutivo = cotizacion.Broker
						this.caso.email = cotizacion.Solicitante
						this.caso.inmuebleNuevo = cotizacion.Nuevo
						this.caso.plaza = cotizacion.Plaza
						this.caso.estado = cotizacion.EstadoInmueble
						this.caso.tasa = cotizacion.PerfilCliente
						this.caso.bancos = cotizacion.ComparaBancos
						this.caso.gastosNotarial=cotizacion.GastoNotarial
						this.caso.PrecalificacionTipo = cotizacion.PrecalificacionTipo ? cotizacion.PrecalificacionTipo : 1;
						this.caso.VersionOrigen = cotizacion.VersionOrigen;				
						this.getBancosEjecutivo(cotizacion.ComparaBancos)
					
					    if (this.destino == 19 || this.destino == 25 || this.destino == 22 || this.destino == 33 || this.destino == 34) {
							this.caso.saldoInsoluto = cotizacion.SaldoInsoluto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							if (cotizacion.PagoMensualActual) { // #98043
								this.caso.pagoMensualActual = cotizacion.PagoMensualActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							}
						}
						this.caso.valorInmueble = cotizacion.ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						
						this.PlazosKrediInicio = []
						for (var i = 0; i < cotizacion.Plazos.length; i++) {

							//alert('caso'+cotizacion.Plazos[i])
							this.caso.plazos.push(cotizacion.Plazos[i])
							this.PlazosKrediInicio.push(cotizacion.Plazos[i])
						}
						//alert('dame'+this.PlazosKrediInicio.length)
						for (var i = 0; i < cotizacion.Esquemas.length; i++) {
							if (cotizacion.Esquemas[i] == 2) {
								this.caso.esquemas.push({ label: 'Pago Fijo', value: 2 })
							} else if (cotizacion.Esquemas[i] == 1) {
								this.caso.esquemas.push({ label: 'Pago creciente', value: 1 })
							}
						}
						this.caso.plazoRestante = cotizacion.PlazoRestante
						this.caso.montoSolicitado = cotizacion.MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						this.caso.favoritos = cotizacion.Favoritos
						if (flag) {
							for (var i = 0; i < this.caso.favoritos.length; i++) {
								this.quitarFavorito(this.caso.favoritos[i])
							}
						}
						if (cotizacion.Programas.length) {
							this.caso.programa = cotizacion.Programas[0]
							this.Programa = cotizacion.Programas[0]
						}
						this.caso.solicitudes = cotizacion.Solicitudes
						for (var i = 0; i < this.caso.solicitudes.length; i++) {
							this.caso.solicitudes[i].Resultado = this.caso.solicitudes[i].Resultado
							this.caso.solicitudes[i].IdCaso = this.caso.solicitudes[i].Caso
							this.caso.solicitudes[i].IdBanco = this.caso.solicitudes[i].IdBanco
							this.caso.solicitudes[i].MensualidadPromedio = this.caso.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							this.caso.solicitudes[i].Cat = this.caso.solicitudes[i].Cat.toFixed(2)
							this.caso.solicitudes[i].CoAcreditado = this.CoAcreditado
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}
						if (this.caso.estado) {
							this.getPlazas()
						}
						if (cotizacion.CreditoInfonavit) {
							this.caso.creditoInf = cotizacion.CreditoInfonavit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}
						if (cotizacion.SubCuentaVivienda) {
							this.caso.ssv = cotizacion.SubCuentaVivienda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}
						this.caso.montoCredito = cotizacion.MontoSolicitado.toString()
							.replace(/\D/g, '')
							.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						if (cotizacion.ExtrasVentaDigital) {
							let auxExtrasVentaDigital = cotizacion.ExtrasVentaDigital.replace(/,+\s+/g,","); //elimina los espacios despues de la coma ","
							this.ExtrasVentaDigital = auxExtrasVentaDigital.replace(/,/g,"\n"); //remplaza la coma por saltos de linea	
						}
						this.programaCofi = cotizacion.Programas[0];
						this.ValorViendaCofi = cotizacion.ValorInmueble;
						this.montoCreditoCofi = cotizacion.MontoSolicitado;
						this.ssvCofi = cotizacion.SubCuentaVivienda
						this.saldoInfo = cotizacion.CreditoInfonavit

						this.dameSolicitante()
						//this.obtenBancos()
						//this.dameLogCotiza();
						//this.updateCaso()

						// aportaciones
						this.hasAportacion = cotizacion.TieneAportacion;
						this.tipoPrepago = cotizacion.PrepagoAportacion;
						this.tipoPlazo = cotizacion.PlazoAportacion;
						this.aportacionVoluntaria = cotizacion.MontoAportacion;
						this.numeroAportacion = cotizacion.CantidadAportaciones;
						this.setFormatApoVol(); // formato para aportacion
						// aportaciones

						//Ministraciones
						this.dameMinistracion()

						this.mostrarProgramas({ isCambioBancos: false })
						this.updateSelected()
						this.validateFavoritos()

						this.$vs.loading.close('#datosCotizacion > .con-vs-loading')
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 6',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraAdicionales() {
			let self = this
			var objRequestAdicionales = {
				strApiKey: this.key,
				strMetodo: 'RegistraAdicionalesSolicitud',
				objCaso: {
					Id: this.id,
					InmuebleGarantia: {
						InmuebleNuevo: this.caso.inmuebleNuevo,
						Estado: this.caso.estado,
					},
				},
			}
			this.$axios
				.post('/', objRequestAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Guardardo exitosamente`,
							position: 'top-right',
						})
						this.dameCaso()
					
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraAdicionalesSolicitud',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 7',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
			}
		},
		getDestinos(strValue) {
			let self = this
			var objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinos',
			}
			this.$axios
				.post('/', objRequestListaDestinos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						// this.destinos = response.data.objContenido
						let vctDestinos = response.data.objContenido;
						switch (strValue) {
							case 1:  // hipotecario, no muestra tipo automotriz ni pyme
								this.destinos = vctDestinos.filter(item => item.Tipo != 10 && item.Tipo != 11);
								break;
							case 3: // auto, muestra solo automotriz
								this.destinos = vctDestinos.filter(item => item.Tipo == 10);
								break;
						}

						/**
						 * Si los destinos de la linea de negocio no tienen el destino actual se pone uno por defecto
						 * Hipotecario = asignar destino 1
						 * Auto = asignar destino 41
						 * 
						 * Se ejecuta antes de la asignacion de destino del componente Automotriz.vue
						 **/
						let searchDestino = this.destinos.find((item) => item.Id == this.destino);
						if (!searchDestino) {
							
							if (this.caso.PrecalificacionTipo == 1) {
								this.destino = 1;
							} else if (this.caso.PrecalificacionTipo == 3) {
								this.destino = 41;
							}
						}

						if (this.caso.PrecalificacionTipo == 1) {
							/**
							 * Se obtiene la lista de bancos completa
							 */
							this.bancos = []
							this.getBancos();
							// console.log( 'BancosHipotecario', this.caso.bancos );

						} else if (this.caso.PrecalificacionTipo == 3) {
							/**
							 * Solo listar bancos disponibles actualmente para autos, hacer dinamica despues
							 */
							//this.bancos = [];
							//this.bancos = this.bancosAuto;

							// this.dameBancosAuto()
							this.getBancosAutoDestino();

							// coincidencias de caso.bancos en bancoAuto
              				/*let cloneCasoBancos = this.caso.bancos;
							this.caso.bancos = [];
							cloneCasoBancos.forEach(banco => {
								let s = this.bancosAuto.find(b => b.value == banco.value);
								if (s) {
									this.caso.bancos.push(banco);
								}
							});*/

							// console.log( 'BancosAuto', this.caso.bancos );
						}

					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaDestinos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 8',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getEstatus() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaCasoEstatus',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estatus = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaCasoEstatus',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 9',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		listaSupervisados() {
			let self = this
			var objRequestListaSupervisados = {
				strApiKey: this.key,
				strMetodo: 'ListaSupervisados',
			}

			this.$axios
				.post('/', objRequestListaSupervisados, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					this.ejecutivos.push(this.$store.state.AppActiveUser)

					if (response.data.intEstatus == 0) {
						for (var i = 0; i < response.data.objContenido.length; i++) {
							this.ejecutivos.push(response.data.objContenido[i])
						}
					}

					this.ejecutivos.sort(function (a, b) {

						const nombre1 = a.Nombre.toLowerCase()
						const nombre2 = b.Nombre.toLowerCase()

						if (nombre1 > nombre2) return 1
						if (nombre1 < nombre2) return -1
						return 0
					})

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 10',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getProgramas() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaProgramas',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.programas = response.data.objContenido
						this.todosProgramas = this.programas
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaProgramas',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 11',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getBancos() {
			let self = this
			var objRequestListaFinancieras = {
				strApiKey: this.key,
				strMetodo: 'ListaFinancieras',
				objEjecutivo: {
					EMail: this.$store.state.AppActiveUser.EMail,
				},
			}
			this.$axios
				.post('/', objRequestListaFinancieras, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let bancos = response.data.objContenido
						for (var i = 0; i < bancos.length; i++) {
							this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
						}
				//	this.bancos.push({ label: 'KREDI', value: 33 })



							
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFinancieras',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema 12',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getBancosEjecutivo(bancosCaso) {
			let self = this
			var objRequestListaFinancieras = {
				strApiKey: this.key,
				strMetodo: 'ListaFinancieras',
				objEjecutivo: {
					EMail: this.$store.state.AppActiveUser.EMail,
				},
			}
			this.$axios
				.post('/', objRequestListaFinancieras, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.bancosEjecutivo = []
						let bancos = response.data.objContenido
						for (var i = 0; i < bancos.length; i++) {
							for (var j = 0; j < bancosCaso.length; j++) {
								if (parseInt(bancosCaso[j]) == bancos[i].Id) {
									this.bancosEjecutivo.push({ label: bancos[i].Nombre, value: bancos[i].Id })
								}
							}
						}
						this.caso.bancos = this.bancosEjecutivo
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFinancieras',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getEstados() {
			let self = this
			var objRequestListaEstados = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaEstados, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getPlazas() {
			let self = this
			var objRequestListaEstados = {
				strApiKey: this.key,
				strMetodo: 'ListaPlazas',
				objPlazas: {
					Estado: this.caso.estado,
				},
			}
			this.$axios
				.post('/', objRequestListaEstados, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.plazas = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaPlazas',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getTasas() {
			let self = this
			var objRequestListaEstados = {
				strApiKey: this.key,
				strMetodo: 'ListaPerfilesCliente',
			}
			this.$axios
				.post('/', objRequestListaEstados, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.tasas = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaPerfilesCliente',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		RegistraLog(flag) {
			let self = this
			var objRequestRegistraLog = {
				strApiKey: this.key,
				strMetodo: 'RegistraLog',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestRegistraLog, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		openPopUp(aux) {
			if (aux) {
				this.ligaCliente = false;	
			} 
			else
				this.ligaCliente = true;

				/*if (this.caso.curp != null && this.caso.curp != "" ){
					//this.GeneraLiga()
					this.url = "https://tuoficina.socasesores.com/Tracking/AccreditedRequestData?AccreditedId="+this.IdLiga
				}			*/
		},
		CierraModal(){
			document.getElementById('ModalLiga').style.display='none'
		},
		dameTokenV3(){

			let objRequestDameTokenPv3 = {
			strApiKey: this.key,
			strMetodo: 'DameTokenPv3'
			}
			this.$axios.post('/', objRequestDameTokenPv3, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response) {
						this.token = response.data.access_token;
						this.GeneraLiga();
					}
					else{
						this.$vs.notify({
							title: 'Ocurrio un error en Obtener token de Pv3',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
			},
		GeneraLiga(){
			let self=this

			var mostrar = ""
			let objRequestCotizaCaso =
				{
				AccreditedModel: {
					Name: this.caso.nombre,
					SName: this.caso.Segnombre,
					LastName: this.caso.appPaterno,
					SecondLastName: this.caso.appMaterno,
					PersonalMail: this.caso.email,
					CellPhone: this.caso.telefono,
					CURP: this.curp,
					RFC: null,  
					CivilStatus: null,
					Nationality: null,
					Gender: null,
					Age: null,
					StudyGrade: null,
					EconomicDependents: null,
					NumberOfChildren: null,
					ChildrensAge: null,
					CP: null,
					MonetaryIncome: null,
					NumberOfCars: null,
					Activity: null,
					PhoneHome: null,
					DateBirtday: null,
					Cocredited: null,
					Weight: null,
					Height: null,
					NSS: null,
					TypeHousing: null,
					ResidenceTimeHome: null,
					ResidenceTimeTown: null,
					CompanyName: null,
					CompanyAddress: null,
					Position: null,
					TypeCompany: null,
					WorkingTime: null,
				},
				ReferencesModel: {
					Name: null,
					SName: null,
					LastName: null,
					SecondLastName: null,
					Phone: null,
					Relationship: null,
					TimeRelationship: null
				},
				CreditRequestModel: {
					TypeCredit: null,
					HomeValue: null,
					CreditAmount: null,
					Plazo: null
				},
				BrokerEmployeeModel: {
					Id: this.SocGuid,
					BrokerId: this.BrokerId,
				//	Id: this.socData.EmployeeId,
                  //  BrokerId: this.socData.BrokerId,
					EmployeeName: null,
					EmployeeEmail: null,
					Origin: "Cotizador soc"
					
				},
				IsQuickQuote:true,
				}   
			var Token = "Bearer "+this.token
			this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestCotizaCaso,{headers : {"authorization": Token}})
			.then(
              response => {
                this.IdLiga = response.data.AccreditedId
				this.RegistraCurpSolicitante()           
                
                
              }
            ).catch(function (error) {
              if(error.response.data.Message == "The CellPhone already exists"){
                mostrar = "El telefono ya se encuentra registrado intente con otro"
              }else if(error.response.data.Message == "The email already exists"){
                mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
              }else if(error.response.data.Message == "CellPhone is required."){
				mostrar = "El Telefono es requerido."
			  }else{
                mostrar = error
              }
              
              self.$vs.notify({
                title:'Ocurrio un error en SISEC',
                text:mostrar,
                color:'danger',
                position:'top-right'
              })
            })
		},
		DameCredenciales(){
			let self=this
			let objRequestDameCredenciales = {
				strApiKey: this.key,
				strMetodo: 'DameCredenciales',
				objBroker: {
				ApiKey: this.key
				}
			}
			this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
				this.SocGuid = response.data.objContenido.SocGuid
				this.BrokerId = response.data.objContenido.BrokerId

				//this.SocGuid = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';//solo pruebas
				//this.BrokerId = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
				}
			).catch(function (error) {
				self.$vs.notify({
				title:'Ocurrio un error de sistema',
				text:error,
				color:'danger',
				position:'top-right'
				})
			})
		},
		EnviaCorreo(){
			let self=this
			let objRequestEnviaLigaCorreo = {
				strApiKey: this.key,
				strMetodo: 'EnviaLigaCorreo',
				objDatos: {
				Nombre: this.caso.nombre,
				Id: this.IdLiga,
				Correo: this.caso.email
				}
			}
			this.$axios.post('/',objRequestEnviaLigaCorreo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {

					this.$vs.notify({
						title: 'Se envió la liga al correo del cliente',
						position: 'top-right',
					})
					//document.getElementById('ModalLiga').style.display='none'
					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				}
			).catch(function (error) {
				self.$vs.notify({
				title:'Ocurrio un error de sistema',
				text:error,
				color:'danger',
				position:'top-right'
				})
			})
		},
		copiarLiga(){
		const element = this.$refs.message;
      	element.select();
		element.setSelectionRange(0, 99999);
		document.execCommand('copy');
		},
		dameLogCotiza(){
			let self = this
			var objRequestDameLogCotiza = {
				strApiKey: this.key,
				strMetodo: 'DameLogCotiza',
				objCotiza: {
						IdCaso: this.id
					}
			}
			this.$axios
				.post('/', objRequestDameLogCotiza, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						if(response.data.objContenido.length == 0){
							this.logEstado = this.caso.estado
							this.logPlaza = this.caso.plaza
							this.logEsNuevo = this.caso.inmuebleNuevo
							this.registraLogCotiza()
						}						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameLogCotiza',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraLogCotiza(){
			let self = this
			var objRequestRegistraLogCotiza = {
				strApiKey: this.key,
				strMetodo: 'RegistraLogCotiza',
				objCotiza: {
						IdCaso: this.id,
						IdEstado: this.logEstado,
       					IdPlaza: this.logPlaza,
						EsNuevo: this.logEsNuevo
					}
			}
			this.$axios
				.post('/', objRequestRegistraLogCotiza, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraLogCotiza',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		}
	}
}
</script>

<style lang="css">
  .v-select ul{
    position: relative !important;
  }
  .filtros{
    display: -webkit-box !important;
    flex-wrap: inherit !important;
  }
  .favoritos{
    border: none !important;
    background: transparent !important;
  }
	.favoritos:disabled {
		background: #079DEF;
	}
  .extrasVentaDigital{
	margin-top: 30px;
	margin-right: 20%;
  }
  .modal-content{
	border-style: solid;
	border-color: #079DEF;
	font-size: small;
  }
  .LigaCliente{
	position: absolute;
	z-index: 2;
	text-align: center;
  }
  .titleLiga{
	font-weight: bold;
	margin: 10px 60px 0 60px;
  }

	.vs-ordenar { 
		max-width: 180px !important;
	}

/* aportacion voluntaria */
.symbol-currency {
	display: inline-block;
	position: relative;
}
.symbol-currency:before {
	content: "$";
	position: absolute;
	left: 8px;
	top: 60%;
	transform: translateY(-50%);
	z-index: 101;
}
.input-currency {
	padding-left: 20px;
}

.btn-seleccionar {
	border: solid 2px #4ED176;
	background: transparent;
	padding: 3px 8px;
	border-radius: 3px;
	color: #4ED176;
	font-weight: bold;
}

.btn-seleccionar.active {
	border: solid 2px #006D4E;
	color: #006D4E;
}
</style>
